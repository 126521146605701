/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
import * as Operations from "../operation";
import * as Urql from "urql";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: string;
  JSON: any;
};

export type AdoptionStatus = {
  __typename?: "AdoptionStatus";
  comment?: Maybe<Scalars["String"]>;
  isAdopted: Scalars["Boolean"];
  isNominated: Scalars["Boolean"];
};

export type AlreadyApplied = {
  __typename?: "AlreadyApplied";
  message: Scalars["String"];
};

export type AnswerInput = {
  confirmation?: InputMaybe<ConfirmationInput>;
  entryResume?: InputMaybe<EntryResumeInput>;
  entryWork?: InputMaybe<EntryWorkInput>;
  file?: InputMaybe<FileInput>;
  multiSelect?: InputMaybe<MultiSelectInput>;
  questionId: Scalars["String"];
  select?: InputMaybe<SelectInput>;
  text?: InputMaybe<TextInput>;
};

export type AvailableWorkingTime = {
  __typename?: "AvailableWorkingTime";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type Career = {
  __typename?: "Career";
  company: Scalars["String"];
  description: Scalars["String"];
  employmentType?: Maybe<EmploymentType>;
  fromDate: Scalars["Date"];
  id: Scalars["String"];
  industry: Industry;
  occupation: Occupation;
  titleType?: Maybe<TitleType>;
  toDate?: Maybe<Scalars["Date"]>;
};

export type CareerInput = {
  company: Scalars["String"];
  description: Scalars["String"];
  employmentTypeId?: InputMaybe<Scalars["ID"]>;
  fromDate: Scalars["DateTime"];
  industryId: Scalars["ID"];
  occupationId: Scalars["ID"];
  titleTypeId?: InputMaybe<Scalars["ID"]>;
  toDate?: InputMaybe<Scalars["DateTime"]>;
};

export type City = {
  __typename?: "City";
  id: Scalars["String"];
  name: Scalars["String"];
  prefectureId: Scalars["Int"];
};

export type Company = {
  __typename?: "Company";
  id: Scalars["ID"];
  name: Scalars["String"];
  nameFurigana?: Maybe<Scalars["String"]>;
};

export type CompetitionProject = Project & {
  __typename?: "CompetitionProject";
  applicantActionDueDate?: Maybe<Scalars["DateTime"]>;
  competitionType: CompetitionType;
  description?: Maybe<Scalars["String"]>;
  entryClosesAt?: Maybe<Scalars["DateTime"]>;
  entryQuestions: Array<EntryQuestion>;
  entryWorksVisibility: EntryWorksVisibility;
  id: Scalars["ID"];
  isEntryClosed: Scalars["Boolean"];
  myEntry?: Maybe<MyEntryResult>;
  name: Scalars["String"];
  overallFeedback: Scalars["String"];
  projectType: ProjectType;
  reviewClosesAt?: Maybe<Scalars["DateTime"]>;
  workCaseType: WorkCaseType;
};

export type CompetitionType = "CORPORATE" | "SNS_CREATIVE" | "SNS_MARKETING" | "SNS_VIDEO" | "WRITER";

export type ConfirmationInput = {
  submittedValue: Scalars["Boolean"];
};

export type ConfirmationQuestion = EntryQuestion & {
  __typename?: "ConfirmationQuestion";
  confirmationText: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  label: Scalars["String"];
  required: Scalars["Boolean"];
};

export type ConfirmationQuestionAnswer = EntryQuestionAnswer & {
  __typename?: "ConfirmationQuestionAnswer";
  questionId: Scalars["String"];
  submittedValue?: Maybe<Scalars["Boolean"]>;
};

export type ContinuousJobSettings = {
  __typename?: "ContinuousJobSettings";
  contractType?: Maybe<ContractType>;
  jobTags?: Maybe<Array<JobTag>>;
  location?: Maybe<Location>;
  occupation?: Maybe<Occupation>;
  subOccupation?: Maybe<SubOccupation>;
};

export type ContractType = "EMPLOYMENT" | "OUTSOURCING" | "SPOT";

export type CopiedDestination = {
  __typename?: "CopiedDestination";
  fileId: Scalars["String"];
  purpose: FilePurpose;
  type: FileType;
};

export type Course = {
  __typename?: "Course";
  displayName: Scalars["String"];
  id: Scalars["Int"];
};

export type CourseResultSummary = {
  __typename?: "CourseResultSummary";
  courseId: Scalars["Int"];
  courseTitle: Scalars["String"];
  isCompleted: Scalars["Boolean"];
  isPassedPracticalExam: Scalars["Boolean"];
  isPassedWrittenExam: Scalars["Boolean"];
};

export type CourseWork = {
  __typename?: "CourseWork";
  course: Course;
  displayName: Scalars["String"];
  id: Scalars["String"];
};

export type CourseWorkInput = {
  courseId?: InputMaybe<Scalars["Int"]>;
};

export type CreateProfileInput = {
  username: Scalars["String"];
};

export type CurrentUser = {
  __typename?: "CurrentUser";
  id: Scalars["ID"];
  isActiveUser: Scalars["Boolean"];
  isGeneralStaffUser: Scalars["Boolean"];
  isWorksAdminUser: Scalars["Boolean"];
  isWorksClientUser: Scalars["Boolean"];
  isWorksShemateUser: Scalars["Boolean"];
  isWorksStaffUser: Scalars["Boolean"];
  name: Scalars["String"];
  pasture?: Maybe<PastureUser>;
  profilePhotoUrl: Scalars["String"];
};

export type EducationalBackground = {
  __typename?: "EducationalBackground";
  id: Scalars["ID"];
  lastEducationalBackground: LastEducationalBackground;
  schoolName?: Maybe<Scalars["String"]>;
};

export type EducationalBackgroundInput = {
  lastEducationalBackground: Scalars["ID"];
  schoolName?: InputMaybe<Scalars["String"]>;
};

export type EmploymentType = {
  __typename?: "EmploymentType";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type Entry = {
  __typename?: "Entry";
  id: Scalars["ID"];
  projectId: Scalars["String"];
  questionAnswers: Array<EntryQuestionAnswer>;
  userId: Scalars["Int"];
};

export type EntryClosed = {
  __typename?: "EntryClosed";
  message: Scalars["String"];
};

export type EntryListResult = EntryListSuccess | NotAuthorized | NotFound;

export type EntryListSuccess = {
  __typename?: "EntryListSuccess";
  result: Array<EntrySummary>;
};

export type EntryQuestion = {
  description?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  label: Scalars["String"];
  required: Scalars["Boolean"];
};

export type EntryQuestionAnswer = {
  questionId: Scalars["String"];
};

export type EntryResumeInput = {
  submittedValue: EntryResumeSubmittedValue;
  version: Scalars["String"];
};

export type EntryResumeQuestion = EntryQuestion & {
  __typename?: "EntryResumeQuestion";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  label: Scalars["String"];
  required: Scalars["Boolean"];
  version: Scalars["String"];
};

export type EntryResumeQuestionAnswer = EntryQuestionAnswer & {
  __typename?: "EntryResumeQuestionAnswer";
  questionId: Scalars["String"];
  submittedValue?: Maybe<UploadedFile>;
};

export type EntryResumeSubmittedValue = {
  uploadedFileId?: InputMaybe<Scalars["ID"]>;
};

export type EntrySummary = JobSelectionEntrySummary;

export type EntryWork = {
  adoptionStatus: AdoptionStatus;
  comment?: Maybe<Scalars["String"]>;
  creatorName?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  projectComment?: Maybe<Scalars["String"]>;
  sheStationUsername?: Maybe<Scalars["String"]>;
  theme?: Maybe<Scalars["String"]>;
  user: User;
};

export type EntryWorkInput = {
  submittedValue: Array<EntryWorkSubmittedValue>;
  version?: InputMaybe<Scalars["String"]>;
};

export type EntryWorkListResult = EntryWorkListSuccess | NotAuthorized | NotFound;

export type EntryWorkListSuccess = {
  __typename?: "EntryWorkListSuccess";
  result: Array<EntryWork>;
};

export type EntryWorkQuestion = EntryQuestion & {
  __typename?: "EntryWorkQuestion";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  label: Scalars["String"];
  required: Scalars["Boolean"];
};

export type EntryWorkQuestionAnswer = EntryQuestionAnswer & {
  __typename?: "EntryWorkQuestionAnswer";
  questionId: Scalars["String"];
  submittedValue: Array<EntryWork>;
};

export type EntryWorkSubmittedValue = {
  comment?: InputMaybe<Scalars["String"]>;
  creatorName?: InputMaybe<Scalars["String"]>;
  externalUrl?: InputMaybe<Scalars["String"]>;
  theme?: InputMaybe<Scalars["String"]>;
  uploadedFiles: Array<EntryWorkUploadedFile>;
};

export type EntryWorkUploadedFile = {
  uploadedFileId: Scalars["ID"];
};

export type EntryWorkV2Question = EntryQuestion & {
  __typename?: "EntryWorkV2Question";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  label: Scalars["String"];
  maxEntryWorks?: Maybe<Scalars["Int"]>;
  required: Scalars["Boolean"];
  requiresCreatorName?: Maybe<Scalars["Boolean"]>;
  themes?: Maybe<Array<Scalars["String"]>>;
  uploadType?: Maybe<UploadType>;
  version: Scalars["String"];
};

export type EntryWorksVisibility = "ALL" | "ONLY_SELECTED";

export type ExternalUrlEntryWork = EntryWork & {
  __typename?: "ExternalUrlEntryWork";
  adoptionStatus: AdoptionStatus;
  comment?: Maybe<Scalars["String"]>;
  copiedDestinations: Array<CopiedDestination>;
  creatorName?: Maybe<Scalars["String"]>;
  externalUrl: Scalars["String"];
  externalUrlForEvaluation: Scalars["String"];
  id: Scalars["ID"];
  projectComment?: Maybe<Scalars["String"]>;
  sheStationUsername?: Maybe<Scalars["String"]>;
  theme?: Maybe<Scalars["String"]>;
  user: User;
};

export type FeatureFlag = {
  __typename?: "FeatureFlag";
  enabled: Scalars["Boolean"];
  key: Scalars["String"];
};

export type FileInput = {
  /** UploadedFileのID */
  submittedValue: Array<Scalars["ID"]>;
};

export type FilePurpose = "MAIN";

export type FileQuestion = EntryQuestion & {
  __typename?: "FileQuestion";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  label: Scalars["String"];
  maxAttachments: Scalars["Int"];
  required: Scalars["Boolean"];
};

export type FileQuestionAnswer = EntryQuestionAnswer & {
  __typename?: "FileQuestionAnswer";
  questionId: Scalars["String"];
  submittedValue: Array<UploadedFile>;
};

export type FileType = "GOOGLE_DRIVE_FILES";

export type FindProjectResult = FindProjectSuccess | NotFound;

export type FindProjectSuccess = {
  __typename?: "FindProjectSuccess";
  result: Project;
};

export type GetJobResult = GetJobSuccess | NotFound;

export type GetJobSuccess = {
  __typename?: "GetJobSuccess";
  job: Job;
};

export type GetOptionsResult = {
  __typename?: "GetOptionsResult";
  availableWorkingTimes: Array<Option>;
  educationalBackgrounds: Array<Option>;
  employmentTypes: Array<Option>;
  idealWorkingStyles: Array<Option>;
  jobChangeWillingness: Array<Option>;
  occupations: Array<Option>;
  sideJobWillingness: Array<Option>;
  skillSetLevels: Array<Option>;
  skillSets: Array<Option>;
  titleTypes: Array<Option>;
  toolLevels: Array<Option>;
  tools: Array<Option>;
  workableHours: Array<Option>;
};

export type GetPublicProjectResult = GetPublicProjectSuccess | InvalidRequest | NotFound;

export type GetPublicProjectSuccess = {
  __typename?: "GetPublicProjectSuccess";
  result?: Maybe<PublicProject>;
};

export type GetResubmittedEntryWorkResult = GetResubmittedEntryWorkSuccess | NotAuthorized | NotFound;

export type GetResubmittedEntryWorkSuccess = {
  __typename?: "GetResubmittedEntryWorkSuccess";
  resubmittedEntryWork: ResubmittedEntryWork;
};

export type IdealWorkingStyle = {
  __typename?: "IdealWorkingStyle";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type Industry = {
  __typename?: "Industry";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type InvalidRequest = {
  __typename?: "InvalidRequest";
  message: Scalars["String"];
};

export type Job = {
  __typename?: "Job";
  benefits?: Maybe<Scalars["String"]>;
  capital?: Maybe<Scalars["String"]>;
  companyName?: Maybe<Scalars["String"]>;
  companyProfile?: Maybe<Scalars["String"]>;
  educationLevel?: Maybe<Array<Scalars["String"]>>;
  employeeCount?: Maybe<Scalars["String"]>;
  employmentType?: Maybe<Array<Scalars["String"]>>;
  establishmentDate?: Maybe<Scalars["String"]>;
  featured: Scalars["Boolean"];
  headquarters?: Maybe<Scalars["String"]>;
  holidays?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  image?: Maybe<Scalars["String"]>;
  industry?: Maybe<Array<Scalars["String"]>>;
  insurance?: Maybe<Scalars["String"]>;
  jobCategoryLv1?: Maybe<Array<Scalars["String"]>>;
  jobDescription?: Maybe<Scalars["String"]>;
  jobTitle?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  otherOffices?: Maybe<Scalars["String"]>;
  prefecture?: Maybe<Array<Scalars["String"]>>;
  region?: Maybe<Array<Scalars["String"]>>;
  remoteWorkOk: Scalars["Boolean"];
  representative?: Maybe<Scalars["String"]>;
  requirements?: Maybe<Scalars["String"]>;
  salary?: Maybe<Scalars["String"]>;
  salaryDetails?: Maybe<Scalars["String"]>;
  selectionProcess?: Maybe<Scalars["String"]>;
  smokingPolicy?: Maybe<Scalars["String"]>;
  smokingPolicyDetails?: Maybe<Scalars["String"]>;
  specificPreferences?: Maybe<Array<Scalars["String"]>>;
  trialPeriod?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
  workHours?: Maybe<Scalars["String"]>;
};

export type JobChangeWillingness = {
  __typename?: "JobChangeWillingness";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type JobConnection = {
  __typename?: "JobConnection";
  nodes: Array<JobSearchResult>;
  pageInfo: OffsetPageInfo;
};

export type JobPrimaryCategory = {
  __typename?: "JobPrimaryCategory";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type JobSearchResult = {
  __typename?: "JobSearchResult";
  companyName?: Maybe<Scalars["String"]>;
  featured: Scalars["Boolean"];
  id: Scalars["String"];
  image?: Maybe<Scalars["String"]>;
  jobCategoryLv1?: Maybe<Array<Scalars["String"]>>;
  jobTitle?: Maybe<Scalars["String"]>;
  prefecture?: Maybe<Array<Scalars["String"]>>;
  specificPreferences?: Maybe<Array<Scalars["String"]>>;
};

export type JobSelectionEntrySummary = {
  __typename?: "JobSelectionEntrySummary";
  id: Scalars["ID"];
  reasonsAndMotivation: Scalars["String"];
  sheProfileUrl: Scalars["String"];
  similarCase: Scalars["String"];
};

export type JobSelectionProject = Project & {
  __typename?: "JobSelectionProject";
  description?: Maybe<Scalars["String"]>;
  entryClosesAt?: Maybe<Scalars["DateTime"]>;
  entryQuestions: Array<EntryQuestion>;
  id: Scalars["ID"];
  isEntryClosed: Scalars["Boolean"];
  myEntry?: Maybe<MyEntryResult>;
  name: Scalars["String"];
  projectType: ProjectType;
  reviewClosesAt?: Maybe<Scalars["DateTime"]>;
  workCaseType: WorkCaseType;
};

export type JobSettings = ContinuousJobSettings | SpotJobSettings;

export type JobSubCategory = {
  __typename?: "JobSubCategory";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type JobTag = {
  __typename?: "JobTag";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type Location = {
  __typename?: "Location";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type MultiSelectInput = {
  submittedValue: Array<Scalars["String"]>;
};

export type MultiSelectQuestion = EntryQuestion & {
  __typename?: "MultiSelectQuestion";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  label: Scalars["String"];
  options: Array<Scalars["String"]>;
  required: Scalars["Boolean"];
};

export type MultiSelectQuestionAnswer = EntryQuestionAnswer & {
  __typename?: "MultiSelectQuestionAnswer";
  questionId: Scalars["String"];
  submittedValue: Array<Scalars["String"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  createProfile?: Maybe<User>;
  createWork?: Maybe<User>;
  requestFileUpload?: Maybe<RequestFileUploadResult>;
  requestUpload: RequestUploadResult;
  resubmitEntryWork: ResubmitEntryWorkResult;
  submitEntry: SubmitEntryResult;
  trackProjectPageAccess: TrackProjectPageAccessResult;
  updateEntry: UpdateEntryResult;
  updateSheProfile: UpdateSheProfileResult;
};

export type MutationCreateProfileArgs = {
  data: CreateProfileInput;
};

export type MutationCreateWorkArgs = {
  data: WorkInput;
};

export type MutationRequestFileUploadArgs = {
  data: RequestUploadInput;
};

export type MutationRequestUploadArgs = {
  input: RequestUploadInput;
};

export type MutationResubmitEntryWorkArgs = {
  input: ResubmitEntryWorkInput;
};

export type MutationSubmitEntryArgs = {
  answers: Array<AnswerInput>;
  projectId: Scalars["String"];
};

export type MutationTrackProjectPageAccessArgs = {
  input: TrackProjectPageAccessInput;
};

export type MutationUpdateEntryArgs = {
  answers: Array<AnswerInput>;
  entryId: Scalars["String"];
};

export type MutationUpdateSheProfileArgs = {
  data: UpdateSheProfileInput;
};

export type MyEntryResult = Entry | NotAuthorized;

export type NotAuthorized = {
  __typename?: "NotAuthorized";
  message: Scalars["String"];
};

export type NotFound = {
  __typename?: "NotFound";
  entityName: Scalars["String"];
  id: Scalars["String"];
};

export type Occupation = {
  __typename?: "Occupation";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type OccurError = {
  __typename?: "OccurError";
  dummy?: Maybe<Scalars["String"]>;
};

export type OffsetPageInfo = {
  __typename?: "OffsetPageInfo";
  hasNextPage: Scalars["Boolean"];
  page?: Maybe<Scalars["Int"]>;
};

export type Option = {
  __typename?: "Option";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type PageInfo = {
  __typename?: "PageInfo";
  endCursor?: Maybe<Scalars["String"]>;
  hasNextPage: Scalars["Boolean"];
};

export type PastureUser = {
  __typename?: "PastureUser";
  isRegistered: Scalars["Boolean"];
};

export type Prefecture = {
  __typename?: "Prefecture";
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type ProfileSearchResult = {
  __typename?: "ProfileSearchResult";
  id: Scalars["ID"];
  introduction?: Maybe<Scalars["String"]>;
  nickname?: Maybe<Scalars["String"]>;
  user?: Maybe<User>;
  username: Scalars["String"];
};

export type ProfileSearchResultConnection = {
  __typename?: "ProfileSearchResultConnection";
  edges: Array<ProfileSearchResultEdge>;
  pageInfo: PageInfo;
};

export type ProfileSearchResultEdge = {
  __typename?: "ProfileSearchResultEdge";
  cursor: Scalars["String"];
  node: ProfileSearchResult;
};

export type Project = {
  description?: Maybe<Scalars["String"]>;
  entryClosesAt?: Maybe<Scalars["DateTime"]>;
  entryQuestions: Array<EntryQuestion>;
  id: Scalars["ID"];
  isEntryClosed: Scalars["Boolean"];
  myEntry?: Maybe<MyEntryResult>;
  name: Scalars["String"];
  projectType: ProjectType;
  reviewClosesAt?: Maybe<Scalars["DateTime"]>;
  workCaseType: WorkCaseType;
};

export type ProjectType = {
  __typename?: "ProjectType";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type PublicProject = {
  __typename?: "PublicProject";
  entryClosesAt: Scalars["DateTime"];
  id: Scalars["ID"];
  jobSettings?: Maybe<JobSettings>;
  name: Scalars["String"];
  projectType: ProjectType;
  requirementDetail?: Maybe<Scalars["JSON"]>;
  thumbnail?: Maybe<UploadedFile>;
  thumbnailUploadedFileId?: Maybe<Scalars["ID"]>;
  workCase?: Maybe<WorkCase>;
  workCaseId?: Maybe<Scalars["ID"]>;
  workCaseType: WorkCaseType;
};

export type PublicProjectConnection = {
  __typename?: "PublicProjectConnection";
  nodes: Array<PublicProject>;
  pageInfo: OffsetPageInfo;
  totalCount: Scalars["Int"];
};

export type Query = {
  __typename?: "Query";
  allCities: Array<City>;
  allCourseWorks: Array<CourseWork>;
  allCoursesWithWork: Array<Course>;
  allIndustries: Array<Industry>;
  allOccupations: Array<Occupation>;
  allPrefectures: Array<Prefecture>;
  allTags: Array<Tag>;
  currentUser?: Maybe<CurrentUser>;
  enabledFeatures: Array<FeatureFlag>;
  entryWorks: EntryWorkListResult;
  getEntries: EntryListResult;
  getJob: GetJobResult;
  getOptions: GetOptionsResult;
  getPublicProject: GetPublicProjectResult;
  getResubmittedEntryWork: GetResubmittedEntryWorkResult;
  helloWorld: Scalars["String"];
  isUsernameAvailable: Scalars["Boolean"];
  me?: Maybe<CurrentUser>;
  occurError?: Maybe<OccurError>;
  project: FindProjectResult;
  searchCount?: Maybe<SearchCount>;
  searchJobs: SearchJobsResult;
  searchProjects: SearchProjectsResult;
  searchResultProfiles?: Maybe<ProfileSearchResultConnection>;
  userProfile?: Maybe<User>;
  usersByIds: Array<Maybe<User>>;
};

export type QueryAllCourseWorksArgs = {
  data: CourseWorkInput;
};

export type QueryEntryWorksArgs = {
  projectId: Scalars["String"];
};

export type QueryGetEntriesArgs = {
  projectId: Scalars["ID"];
};

export type QueryGetJobArgs = {
  id: Scalars["ID"];
};

export type QueryGetPublicProjectArgs = {
  id: Scalars["ID"];
  signature?: InputMaybe<Scalars["String"]>;
};

export type QueryGetResubmittedEntryWorkArgs = {
  entryWorkId: Scalars["ID"];
};

export type QueryIsUsernameAvailableArgs = {
  data: UsernameAvailableInput;
};

export type QueryProjectArgs = {
  id: Scalars["ID"];
};

export type QuerySearchCountArgs = {
  data: SearchCountInput;
};

export type QuerySearchJobsArgs = {
  input: SearchJobsInput;
  itemsPerPage: Scalars["Int"];
  page?: InputMaybe<Scalars["Int"]>;
};

export type QuerySearchProjectsArgs = {
  input: SearchProjectsInput;
  itemsPerPage: Scalars["Int"];
  page?: InputMaybe<Scalars["Int"]>;
};

export type QuerySearchResultProfilesArgs = {
  data: SearchProfileInput;
};

export type QueryUserProfileArgs = {
  data: UserProfileInput;
};

export type QueryUsersByIdsArgs = {
  ids: Array<Scalars["ID"]>;
};

export type RequestFileUploadResult = {
  __typename?: "RequestFileUploadResult";
  contentType: Scalars["String"];
  fileName: Scalars["String"];
  filePath: Scalars["String"];
  id: Scalars["ID"];
  uploadUrl: Scalars["String"];
};

export type RequestUploadInput = {
  contentType: Scalars["String"];
  fileName: Scalars["String"];
  isConfidential?: InputMaybe<Scalars["Boolean"]>;
};

export type RequestUploadResult = InvalidRequest | NotAuthorized | RequestUploadSuccess;

export type RequestUploadSuccess = {
  __typename?: "RequestUploadSuccess";
  result: UploadedFile;
};

export type ResubmitEntryWorkInput = {
  entryWorkId: Scalars["ID"];
  uploadedFileIds: Array<Scalars["ID"]>;
};

export type ResubmitEntryWorkResult = InvalidRequest | NotAuthorized | NotFound | ResubmitEntryWorkSuccess;

export type ResubmitEntryWorkSuccess = {
  __typename?: "ResubmitEntryWorkSuccess";
  resubmittedEntryWorkId?: Maybe<Scalars["ID"]>;
};

export type ResubmittedEntryWork = {
  entryWorkId: Scalars["ID"];
  id: Scalars["ID"];
};

export type SearchCount = {
  __typename?: "SearchCount";
  count: Scalars["Int"];
};

export type SearchCountInput = {
  tagIds: Array<Scalars["String"]>;
};

export type SearchJobsInput = {
  featured?: InputMaybe<Scalars["Boolean"]>;
  jobCategoryLv1?: InputMaybe<Array<Scalars["String"]>>;
  locations?: InputMaybe<Array<Scalars["String"]>>;
  specificPreferences?: InputMaybe<Array<Scalars["String"]>>;
};

export type SearchJobsResult = JobConnection | NotAuthorized | NotFound;

export type SearchProfileInput = {
  after?: InputMaybe<Scalars["String"]>;
  first: Scalars["Int"];
  tagIds: Array<Scalars["String"]>;
};

export type SearchProjectsInput = {
  contractType?: InputMaybe<ContractType>;
  onlyShowOpen?: InputMaybe<Scalars["Boolean"]>;
};

export type SearchProjectsResult = NotAuthorized | NotFound | PublicProjectConnection;

export type SelectInput = {
  submittedValue?: InputMaybe<Scalars["String"]>;
};

export type SelectQuestion = EntryQuestion & {
  __typename?: "SelectQuestion";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  label: Scalars["String"];
  options: Array<Scalars["String"]>;
  required: Scalars["Boolean"];
};

export type SelectQuestionAnswer = EntryQuestionAnswer & {
  __typename?: "SelectQuestionAnswer";
  questionId: Scalars["String"];
  submittedValue?: Maybe<Scalars["String"]>;
};

export type SideJobWillingness = {
  __typename?: "SideJobWillingness";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type SkillSet = {
  __typename?: "SkillSet";
  id: Scalars["ID"];
  levelId?: Maybe<Scalars["ID"]>;
  levelName?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  position: Scalars["Int"];
};

export type SkillSetInput = {
  levelId: Scalars["ID"];
  skillSetId: Scalars["ID"];
};

export type SpotJobSettings = {
  __typename?: "SpotJobSettings";
  contractType?: Maybe<ContractType>;
  jobPrimaryCategory?: Maybe<JobPrimaryCategory>;
  jobSubCategory?: Maybe<JobSubCategory>;
  jobTags?: Maybe<Array<JobTag>>;
  location?: Maybe<Location>;
};

export type SubOccupation = {
  __typename?: "SubOccupation";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type SubmitEntryResult = AlreadyApplied | EntryClosed | InvalidRequest | NotAuthorized | SubmitEntrySuccess;

export type SubmitEntrySuccess = {
  __typename?: "SubmitEntrySuccess";
  result: Entry;
};

export type Tag = {
  __typename?: "Tag";
  category: TagCategory;
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type TagCategory = {
  __typename?: "TagCategory";
  id: Scalars["ID"];
  key: Scalars["String"];
};

export type TextInput = {
  submittedValue?: InputMaybe<Scalars["String"]>;
};

export type TextQuestion = EntryQuestion & {
  __typename?: "TextQuestion";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  label: Scalars["String"];
  multiLine: Scalars["Boolean"];
  placeholder: Scalars["String"];
  required: Scalars["Boolean"];
};

export type TextQuestionAnswer = EntryQuestionAnswer & {
  __typename?: "TextQuestionAnswer";
  questionId: Scalars["String"];
  submittedValue?: Maybe<Scalars["String"]>;
};

export type TitleType = {
  __typename?: "TitleType";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type ToolInput = {
  levelId: Scalars["ID"];
  toolId: Scalars["ID"];
};

export type ToolV2 = {
  __typename?: "ToolV2";
  id: Scalars["ID"];
  levelId?: Maybe<Scalars["ID"]>;
  levelName?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  position: Scalars["Int"];
};

export type TrackProjectPageAccessInput = {
  projectPageId: Scalars["ID"];
};

export type TrackProjectPageAccessResult = InvalidRequest | NotAuthorized | TrackProjectPageAccessSuccess;

export type TrackProjectPageAccessSuccess = {
  __typename?: "TrackProjectPageAccessSuccess";
  projectPageAccessHistoryId: Scalars["ID"];
};

export type UpdateEntryResult = EntryClosed | InvalidRequest | NotAuthorized | NotFound | UpdateEntrySuccess;

export type UpdateEntrySuccess = {
  __typename?: "UpdateEntrySuccess";
  result: Entry;
};

export type UpdateSheProfileInput = {
  availableWorkingTimes?: InputMaybe<Array<Scalars["ID"]>>;
  birthday?: InputMaybe<Scalars["DateTime"]>;
  careers?: InputMaybe<Array<CareerInput>>;
  cityId?: InputMaybe<Scalars["String"]>;
  educationalBackground?: InputMaybe<EducationalBackgroundInput>;
  facebook?: InputMaybe<Scalars["String"]>;
  idealWorkingStyles?: InputMaybe<Array<Scalars["ID"]>>;
  instagram?: InputMaybe<Scalars["String"]>;
  introduction?: InputMaybe<Scalars["String"]>;
  jobChangeWillingness?: InputMaybe<Scalars["ID"]>;
  nickname?: InputMaybe<Scalars["String"]>;
  occupations?: InputMaybe<Array<Scalars["ID"]>>;
  portfolio?: InputMaybe<Scalars["String"]>;
  prefectureId?: InputMaybe<Scalars["Int"]>;
  sideJobWillingness?: InputMaybe<Scalars["ID"]>;
  skillSets?: InputMaybe<Array<SkillSetInput>>;
  tagIds?: InputMaybe<Array<Scalars["String"]>>;
  tools?: InputMaybe<Array<ToolInput>>;
  twitter?: InputMaybe<Scalars["String"]>;
  workableHours?: InputMaybe<Scalars["ID"]>;
  works?: InputMaybe<Array<WorkInput>>;
};

export type UpdateSheProfileResult = UpdateSheProfileSuccess;

export type UpdateSheProfileSuccess = {
  __typename?: "UpdateSheProfileSuccess";
  id: Scalars["ID"];
};

export type UpdateUserProfileInput = {
  birthday: Scalars["DateTime"];
  careers?: InputMaybe<Array<CareerInput>>;
  cityId: Scalars["String"];
  facebook?: InputMaybe<Scalars["String"]>;
  instagram?: InputMaybe<Scalars["String"]>;
  introduction: Scalars["String"];
  nickname: Scalars["String"];
  prefectureId: Scalars["Int"];
  tagIds?: InputMaybe<Array<Scalars["String"]>>;
  twitter?: InputMaybe<Scalars["String"]>;
};

export type UploadType = "FILE" | "FILE_AND_URL" | "URL";

export type UploadedFile = {
  __typename?: "UploadedFile";
  contentType: Scalars["String"];
  fileName: Scalars["String"];
  filePath: Scalars["String"];
  id: Scalars["ID"];
  previewUrl: Scalars["String"];
  uploadUrl: Scalars["String"];
};

export type UploadedFileEntryWork = EntryWork & {
  __typename?: "UploadedFileEntryWork";
  adoptionStatus: AdoptionStatus;
  comment?: Maybe<Scalars["String"]>;
  creatorName?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  projectComment?: Maybe<Scalars["String"]>;
  resubmittedEntryWork?: Maybe<ResubmittedEntryWork>;
  sheStationUsername?: Maybe<Scalars["String"]>;
  theme?: Maybe<Scalars["String"]>;
  uploadedFiles: Array<UploadedFile>;
  user: User;
};

export type UploadedFileResubmittedEntryWork = ResubmittedEntryWork & {
  __typename?: "UploadedFileResubmittedEntryWork";
  entryWorkId: Scalars["ID"];
  id: Scalars["ID"];
  uploadedFiles: Array<UploadedFile>;
};

export type User = {
  __typename?: "User";
  courseResultSummaries: Array<CourseResultSummary>;
  id: Scalars["ID"];
  likesProfile?: Maybe<UserLikesProfile>;
  name: Scalars["String"];
  profile?: Maybe<UserProfile>;
  profilePhotoUrl?: Maybe<Scalars["String"]>;
  uid: Scalars["ID"];
  workProfile?: Maybe<WorkProfile>;
};

export type UserLikesProfile = {
  __typename?: "UserLikesProfile";
  id: Scalars["ID"];
  tags: Array<Tag>;
  works: Array<Work>;
};

export type UserProfile = {
  __typename?: "UserProfile";
  birthday?: Maybe<Scalars["DateTime"]>;
  careers: Array<Career>;
  city?: Maybe<City>;
  cityId?: Maybe<Scalars["String"]>;
  educationalBackground?: Maybe<EducationalBackground>;
  facebook?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  instagram?: Maybe<Scalars["String"]>;
  introduction?: Maybe<Scalars["String"]>;
  nickname?: Maybe<Scalars["String"]>;
  prefecture?: Maybe<Prefecture>;
  prefectureId?: Maybe<Scalars["Int"]>;
  skillSets: Array<SkillSet>;
  tags?: Maybe<Array<Tag>>;
  tools: Array<ToolV2>;
  twitter?: Maybe<Scalars["String"]>;
  username: Scalars["String"];
};

export type UserProfileInput = {
  username: Scalars["String"];
};

export type UsernameAvailableInput = {
  username: Scalars["String"];
};

export type Work = {
  __typename?: "Work";
  id: Scalars["String"];
  title: Scalars["String"];
  uploadedFile?: Maybe<UploadedFile>;
  url?: Maybe<Scalars["String"]>;
  workTarget?: Maybe<CourseWork>;
  workType: WorkType;
  workedAt: Scalars["Date"];
};

export type WorkCase = {
  __typename?: "WorkCase";
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars["ID"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type WorkCaseType = "BUSINESS" | "OTHER" | "SHE";

export type WorkInput = {
  title: Scalars["String"];
  uploadedFileId?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
  workTargetId?: InputMaybe<Scalars["String"]>;
  workType: WorkType;
  workedAt: Scalars["DateTime"];
};

export type WorkProfile = {
  __typename?: "WorkProfile";
  availableWorkingTimes: Array<AvailableWorkingTime>;
  idealWorkingStyles: Array<IdealWorkingStyle>;
  introduction?: Maybe<Scalars["String"]>;
  jobChangeWillingness?: Maybe<JobChangeWillingness>;
  occupations: Array<Occupation>;
  portfolio?: Maybe<Scalars["String"]>;
  sideJobWillingness?: Maybe<SideJobWillingness>;
  workableHours?: Maybe<WorkableHour>;
};

export type WorkType = "COURSE_WORK" | "SELF";

export type WorkableHour = {
  __typename?: "WorkableHour";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type LastEducationalBackground = {
  __typename?: "lastEducationalBackground";
  id: Scalars["ID"];
  name: Scalars["String"];
};

type ProjectField_CompetitionProject_Fragment = { __typename?: "CompetitionProject"; id: string; name: string };

type ProjectField_JobSelectionProject_Fragment = { __typename?: "JobSelectionProject"; id: string; name: string };

export type ProjectFieldFragment = ProjectField_CompetitionProject_Fragment | ProjectField_JobSelectionProject_Fragment;

type BaseQuestionField_ConfirmationQuestion_Fragment = {
  __typename?: "ConfirmationQuestion";
  description?: string | null;
  label: string;
  required: boolean;
};

type BaseQuestionField_EntryResumeQuestion_Fragment = {
  __typename?: "EntryResumeQuestion";
  description?: string | null;
  label: string;
  required: boolean;
};

type BaseQuestionField_EntryWorkQuestion_Fragment = {
  __typename?: "EntryWorkQuestion";
  description?: string | null;
  label: string;
  required: boolean;
};

type BaseQuestionField_EntryWorkV2Question_Fragment = {
  __typename?: "EntryWorkV2Question";
  description?: string | null;
  label: string;
  required: boolean;
};

type BaseQuestionField_FileQuestion_Fragment = {
  __typename?: "FileQuestion";
  description?: string | null;
  label: string;
  required: boolean;
};

type BaseQuestionField_MultiSelectQuestion_Fragment = {
  __typename?: "MultiSelectQuestion";
  description?: string | null;
  label: string;
  required: boolean;
};

type BaseQuestionField_SelectQuestion_Fragment = {
  __typename?: "SelectQuestion";
  description?: string | null;
  label: string;
  required: boolean;
};

type BaseQuestionField_TextQuestion_Fragment = {
  __typename?: "TextQuestion";
  description?: string | null;
  label: string;
  required: boolean;
};

export type BaseQuestionFieldFragment =
  | BaseQuestionField_ConfirmationQuestion_Fragment
  | BaseQuestionField_EntryResumeQuestion_Fragment
  | BaseQuestionField_EntryWorkQuestion_Fragment
  | BaseQuestionField_EntryWorkV2Question_Fragment
  | BaseQuestionField_FileQuestion_Fragment
  | BaseQuestionField_MultiSelectQuestion_Fragment
  | BaseQuestionField_SelectQuestion_Fragment
  | BaseQuestionField_TextQuestion_Fragment;

export type ConfirmationQuestionFieldsFragment = {
  __typename: "ConfirmationQuestion";
  confirmationText: string;
  description?: string | null;
  id: string;
  label: string;
  required: boolean;
};

export type ConfirmationQuestionAnswerFieldsFragment = {
  __typename: "ConfirmationQuestionAnswer";
  questionId: string;
  confirmationQuestionSubmittedValue?: boolean | null;
};

export type EntryResumeQuestionFieldsFragment = {
  __typename: "EntryResumeQuestion";
  version: string;
  description?: string | null;
  id: string;
  label: string;
  required: boolean;
};

export type EntryResumeQuestionAnswerFieldsFragment = {
  __typename: "EntryResumeQuestionAnswer";
  questionId: string;
  entryResumeQuestionSubmittedValue?: {
    __typename?: "UploadedFile";
    id: string;
    fileName: string;
    filePath: string;
    previewUrl: string;
    contentType: string;
    uploadedFileId: string;
  } | null;
};

export type FormEntryResumeRequestUploadMutationVariables = Exact<{
  input: RequestUploadInput;
}>;

export type FormEntryResumeRequestUploadMutation = {
  __typename?: "Mutation";
  requestUpload:
    | { __typename: "InvalidRequest"; message: string }
    | { __typename: "NotAuthorized" }
    | {
        __typename: "RequestUploadSuccess";
        result: { __typename?: "UploadedFile"; id: string; uploadUrl: string; filePath: string; contentType: string };
      };
};

export type EntryWorkV2QuestionFieldsFragment = {
  __typename: "EntryWorkV2Question";
  version: string;
  description?: string | null;
  id: string;
  label: string;
  required: boolean;
  uploadType?: UploadType | null;
  maxEntryWorks?: number | null;
  themes?: Array<string> | null;
  requiresCreatorName?: boolean | null;
};

export type EntryWorkQuestionAnswerFieldsFragment = {
  __typename: "EntryWorkQuestionAnswer";
  questionId: string;
  entryWorkQuestionSubmittedValue: Array<
    | {
        __typename: "ExternalUrlEntryWork";
        externalUrl: string;
        comment?: string | null;
        theme?: string | null;
        creatorName?: string | null;
        id: string;
        adoptionStatus: { __typename?: "AdoptionStatus"; isAdopted: boolean; isNominated: boolean };
      }
    | {
        __typename: "UploadedFileEntryWork";
        comment?: string | null;
        theme?: string | null;
        creatorName?: string | null;
        id: string;
        uploadedFiles: Array<{
          __typename?: "UploadedFile";
          id: string;
          fileName: string;
          filePath: string;
          previewUrl: string;
          contentType: string;
        }>;
        adoptionStatus: { __typename?: "AdoptionStatus"; isAdopted: boolean; isNominated: boolean };
      }
  >;
};

export type EntryWorkV2QuestionFormRequestUploadMutationVariables = Exact<{
  input: RequestUploadInput;
}>;

export type EntryWorkV2QuestionFormRequestUploadMutation = {
  __typename?: "Mutation";
  requestUpload:
    | { __typename: "InvalidRequest"; message: string }
    | { __typename: "NotAuthorized" }
    | {
        __typename: "RequestUploadSuccess";
        result: { __typename?: "UploadedFile"; id: string; uploadUrl: string; filePath: string; contentType: string };
      };
};

export type EntryWorkQuestionFieldsFragment = {
  __typename: "EntryWorkQuestion";
  description?: string | null;
  id: string;
  label: string;
  required: boolean;
};

export type EntryWorkQuestionFormRequestUploadMutationVariables = Exact<{
  input: RequestUploadInput;
}>;

export type EntryWorkQuestionFormRequestUploadMutation = {
  __typename?: "Mutation";
  requestUpload:
    | { __typename: "InvalidRequest"; message: string }
    | { __typename: "NotAuthorized" }
    | {
        __typename: "RequestUploadSuccess";
        result: { __typename?: "UploadedFile"; id: string; uploadUrl: string; filePath: string; contentType: string };
      };
};

export type FileQuestionFieldsFragment = {
  __typename: "FileQuestion";
  description?: string | null;
  id: string;
  label: string;
  required: boolean;
  maxAttachments: number;
};

export type FileQuestionAnswerFieldsFragment = {
  __typename: "FileQuestionAnswer";
  questionId: string;
  fileQuestionSubmittedValue: Array<{
    __typename?: "UploadedFile";
    id: string;
    fileName: string;
    filePath: string;
    previewUrl: string;
    contentType: string;
  }>;
};

export type FileQuestionFormRequestUploadMutationVariables = Exact<{
  input: RequestUploadInput;
}>;

export type FileQuestionFormRequestUploadMutation = {
  __typename?: "Mutation";
  requestUpload:
    | { __typename: "InvalidRequest"; message: string }
    | { __typename: "NotAuthorized" }
    | {
        __typename: "RequestUploadSuccess";
        result: { __typename?: "UploadedFile"; id: string; uploadUrl: string; filePath: string; contentType: string };
      };
};

export type MultiSelectQuestionFieldsFragment = {
  __typename: "MultiSelectQuestion";
  description?: string | null;
  id: string;
  label: string;
  options: Array<string>;
  required: boolean;
};

export type MultiSelectQuestionAnswerFieldsFragment = {
  __typename: "MultiSelectQuestionAnswer";
  questionId: string;
  multiSelectQuestionSubmittedValue: Array<string>;
};

export type SelectQuestionFieldsFragment = {
  __typename: "SelectQuestion";
  description?: string | null;
  id: string;
  label: string;
  options: Array<string>;
  required: boolean;
};

export type SelectQuestionAnswerFieldsFragment = {
  __typename: "SelectQuestionAnswer";
  questionId: string;
  selectQuestionSubmittedValue?: string | null;
};

export type TextQuestionFieldsFragment = {
  __typename: "TextQuestion";
  description?: string | null;
  id: string;
  label: string;
  required: boolean;
  placeholder: string;
  multiLine: boolean;
};

export type TextQuestionAnswerFieldsFragment = {
  __typename: "TextQuestionAnswer";
  questionId: string;
  textQuestionSubmittedValue?: string | null;
};

type ProjectHeaderField_CompetitionProject_Fragment = { __typename?: "CompetitionProject"; name: string };

type ProjectHeaderField_JobSelectionProject_Fragment = { __typename?: "JobSelectionProject"; name: string };

export type ProjectHeaderFieldFragment =
  | ProjectHeaderField_CompetitionProject_Fragment
  | ProjectHeaderField_JobSelectionProject_Fragment;

export type FileUploadRequestUploadMutationVariables = Exact<{
  input: RequestUploadInput;
}>;

export type FileUploadRequestUploadMutation = {
  __typename?: "Mutation";
  requestUpload:
    | { __typename: "InvalidRequest"; message: string }
    | { __typename: "NotAuthorized" }
    | {
        __typename: "RequestUploadSuccess";
        result: { __typename?: "UploadedFile"; id: string; uploadUrl: string; filePath: string; contentType: string };
      };
};

export type SearchPublicProjectsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  itemsPerPage: Scalars["Int"];
  input: SearchProjectsInput;
}>;

export type SearchPublicProjectsQuery = {
  __typename?: "Query";
  searchProjects:
    | { __typename: "NotAuthorized" }
    | { __typename: "NotFound" }
    | {
        __typename: "PublicProjectConnection";
        totalCount: number;
        nodes: Array<{
          __typename: "PublicProject";
          id: string;
          name: string;
          entryClosesAt: string;
          thumbnail?: { __typename?: "UploadedFile"; previewUrl: string } | null;
          workCase?: {
            __typename?: "WorkCase";
            company?: { __typename?: "Company"; nameFurigana?: string | null } | null;
          } | null;
          jobSettings?:
            | {
                __typename?: "ContinuousJobSettings";
                location?: { __typename?: "Location"; id: string; name: string } | null;
                jobTags?: Array<{ __typename?: "JobTag"; id: string; name: string }> | null;
                subOccupation?: { __typename?: "SubOccupation"; id: string; name: string } | null;
              }
            | {
                __typename?: "SpotJobSettings";
                location?: { __typename?: "Location"; id: string; name: string } | null;
                jobTags?: Array<{ __typename?: "JobTag"; id: string; name: string }> | null;
                jobSubCategory?: { __typename?: "JobSubCategory"; id: string; name: string } | null;
              }
            | null;
        }>;
        pageInfo: { __typename?: "OffsetPageInfo"; page?: number | null; hasNextPage: boolean };
      };
};

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename: "CurrentUser";
    id: string;
    name: string;
    profilePhotoUrl: string;
    isActiveUser: boolean;
    isGeneralStaffUser: boolean;
    isWorksAdminUser: boolean;
    isWorksStaffUser: boolean;
    isWorksClientUser: boolean;
    isWorksShemateUser: boolean;
  } | null;
  enabledFeatures: Array<{ __typename: "FeatureFlag"; enabled: boolean; key: string }>;
};

export type JobDetailFieldFragment = {
  __typename?: "Job";
  id: string;
  jobTitle?: string | null;
  jobDescription?: string | null;
  requirements?: string | null;
  location?: string | null;
  salary?: string | null;
  salaryDetails?: string | null;
  workHours?: string | null;
  holidays?: string | null;
  insurance?: string | null;
  benefits?: string | null;
  trialPeriod?: string | null;
  selectionProcess?: string | null;
  companyName?: string | null;
  website?: string | null;
  headquarters?: string | null;
  otherOffices?: string | null;
  establishmentDate?: string | null;
  capital?: string | null;
  employeeCount?: string | null;
  representative?: string | null;
  companyProfile?: string | null;
  smokingPolicy?: string | null;
  smokingPolicyDetails?: string | null;
  employmentType?: Array<string> | null;
  educationLevel?: Array<string> | null;
  region?: Array<string> | null;
  industry?: Array<string> | null;
  remoteWorkOk: boolean;
  image?: string | null;
  specificPreferences?: Array<string> | null;
  jobCategoryLv1?: Array<string> | null;
  prefecture?: Array<string> | null;
};

export type JobWidgetFieldFragment = {
  __typename?: "Job";
  id: string;
  image?: string | null;
  jobTitle?: string | null;
  companyName?: string | null;
  specificPreferences?: Array<string> | null;
  jobCategoryLv1?: Array<string> | null;
  prefecture?: Array<string> | null;
};

export type GetJobQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetJobQuery = {
  __typename?: "Query";
  getJob:
    | {
        __typename: "GetJobSuccess";
        job: {
          __typename?: "Job";
          id: string;
          jobTitle?: string | null;
          jobDescription?: string | null;
          requirements?: string | null;
          location?: string | null;
          salary?: string | null;
          salaryDetails?: string | null;
          workHours?: string | null;
          holidays?: string | null;
          insurance?: string | null;
          benefits?: string | null;
          trialPeriod?: string | null;
          selectionProcess?: string | null;
          companyName?: string | null;
          website?: string | null;
          headquarters?: string | null;
          otherOffices?: string | null;
          establishmentDate?: string | null;
          capital?: string | null;
          employeeCount?: string | null;
          representative?: string | null;
          companyProfile?: string | null;
          smokingPolicy?: string | null;
          smokingPolicyDetails?: string | null;
          employmentType?: Array<string> | null;
          educationLevel?: Array<string> | null;
          region?: Array<string> | null;
          industry?: Array<string> | null;
          remoteWorkOk: boolean;
          image?: string | null;
          specificPreferences?: Array<string> | null;
          jobCategoryLv1?: Array<string> | null;
          prefecture?: Array<string> | null;
        };
      }
    | { __typename: "NotFound" };
};

export type JobCardFieldsFragment = {
  __typename?: "JobSearchResult";
  id: string;
  image?: string | null;
  jobTitle?: string | null;
  companyName?: string | null;
  featured: boolean;
  jobCategoryLv1?: Array<string> | null;
  prefecture?: Array<string> | null;
  specificPreferences?: Array<string> | null;
};

export type SearchJobsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  itemsPerPage: Scalars["Int"];
  input: SearchJobsInput;
}>;

export type SearchJobsQuery = {
  __typename?: "Query";
  searchJobs:
    | {
        __typename: "JobConnection";
        nodes: Array<{
          __typename?: "JobSearchResult";
          id: string;
          image?: string | null;
          jobTitle?: string | null;
          companyName?: string | null;
          featured: boolean;
          jobCategoryLv1?: Array<string> | null;
          prefecture?: Array<string> | null;
          specificPreferences?: Array<string> | null;
        }>;
        pageInfo: { __typename?: "OffsetPageInfo"; hasNextPage: boolean; page?: number | null };
      }
    | { __typename: "NotAuthorized" }
    | { __typename: "NotFound" };
};

export type ProjectEntryWidgetFieldFragment = {
  __typename?: "PublicProject";
  id: string;
  workCaseType: WorkCaseType;
  entryClosesAt: string;
  projectType: { __typename?: "ProjectType"; id: string };
  thumbnail?: { __typename?: "UploadedFile"; previewUrl: string } | null;
};

export type PublicProjectTopFieldFragment = {
  __typename?: "PublicProject";
  name: string;
  requirementDetail?: any | null;
  entryClosesAt: string;
  id: string;
  workCaseType: WorkCaseType;
  projectType: { __typename?: "ProjectType"; id: string };
  thumbnail?: { __typename?: "UploadedFile"; previewUrl: string } | null;
};

export type GetEntryWorksQueryVariables = Exact<{
  projectId: Scalars["String"];
}>;

export type GetEntryWorksQuery = {
  __typename?: "Query";
  entryWorks:
    | {
        __typename: "EntryWorkListSuccess";
        result: Array<
          | {
              __typename: "ExternalUrlEntryWork";
              externalUrl: string;
              id: string;
              projectComment?: string | null;
              sheStationUsername?: string | null;
              comment?: string | null;
              adoptionStatus: {
                __typename?: "AdoptionStatus";
                isAdopted: boolean;
                isNominated: boolean;
                comment?: string | null;
              };
            }
          | {
              __typename: "UploadedFileEntryWork";
              id: string;
              comment?: string | null;
              projectComment?: string | null;
              sheStationUsername?: string | null;
              uploadedFiles: Array<{
                __typename?: "UploadedFile";
                contentType: string;
                previewUrl: string;
                fileName: string;
              }>;
              adoptionStatus: {
                __typename?: "AdoptionStatus";
                isAdopted: boolean;
                isNominated: boolean;
                comment?: string | null;
              };
            }
        >;
      }
    | { __typename: "NotAuthorized" }
    | { __typename: "NotFound" };
};

type CompetitionEntriesField_CompetitionProject_Fragment = {
  __typename?: "CompetitionProject";
  entryWorksVisibility: EntryWorksVisibility;
  id: string;
  name: string;
};

type CompetitionEntriesField_JobSelectionProject_Fragment = {
  __typename?: "JobSelectionProject";
  id: string;
  name: string;
};

export type CompetitionEntriesFieldFragment =
  | CompetitionEntriesField_CompetitionProject_Fragment
  | CompetitionEntriesField_JobSelectionProject_Fragment;

type EntryWorkCardField_ExternalUrlEntryWork_Fragment = {
  __typename: "ExternalUrlEntryWork";
  externalUrl: string;
  id: string;
  projectComment?: string | null;
  sheStationUsername?: string | null;
  comment?: string | null;
  adoptionStatus: { __typename?: "AdoptionStatus"; isAdopted: boolean; isNominated: boolean; comment?: string | null };
};

type EntryWorkCardField_UploadedFileEntryWork_Fragment = {
  __typename: "UploadedFileEntryWork";
  id: string;
  comment?: string | null;
  projectComment?: string | null;
  sheStationUsername?: string | null;
  uploadedFiles: Array<{ __typename?: "UploadedFile"; contentType: string; previewUrl: string; fileName: string }>;
  adoptionStatus: { __typename?: "AdoptionStatus"; isAdopted: boolean; isNominated: boolean; comment?: string | null };
};

export type EntryWorkCardFieldFragment =
  | EntryWorkCardField_ExternalUrlEntryWork_Fragment
  | EntryWorkCardField_UploadedFileEntryWork_Fragment;

export type EntryWorkThumbnailFieldFragment = { __typename?: "UploadedFile"; contentType: string; previewUrl: string };

type EntryWorkModalField_ExternalUrlEntryWork_Fragment = {
  __typename: "ExternalUrlEntryWork";
  externalUrl: string;
  id: string;
  projectComment?: string | null;
  sheStationUsername?: string | null;
  comment?: string | null;
  adoptionStatus: { __typename?: "AdoptionStatus"; isAdopted: boolean; isNominated: boolean; comment?: string | null };
};

type EntryWorkModalField_UploadedFileEntryWork_Fragment = {
  __typename: "UploadedFileEntryWork";
  comment?: string | null;
  id: string;
  projectComment?: string | null;
  sheStationUsername?: string | null;
  uploadedFiles: Array<{ __typename?: "UploadedFile"; contentType: string; previewUrl: string; fileName: string }>;
  adoptionStatus: { __typename?: "AdoptionStatus"; isAdopted: boolean; isNominated: boolean; comment?: string | null };
};

export type EntryWorkModalFieldFragment =
  | EntryWorkModalField_ExternalUrlEntryWork_Fragment
  | EntryWorkModalField_UploadedFileEntryWork_Fragment;

type CustomPagingEntryWorkField_ExternalUrlEntryWork_Fragment = { __typename: "ExternalUrlEntryWork" };

type CustomPagingEntryWorkField_UploadedFileEntryWork_Fragment = {
  __typename: "UploadedFileEntryWork";
  uploadedFiles: Array<{ __typename?: "UploadedFile"; id: string; previewUrl: string; contentType: string }>;
};

export type CustomPagingEntryWorkFieldFragment =
  | CustomPagingEntryWorkField_ExternalUrlEntryWork_Fragment
  | CustomPagingEntryWorkField_UploadedFileEntryWork_Fragment;

export type EntryWorkBeforeAfterFieldFragment = {
  __typename: "UploadedFileEntryWork";
  id: string;
  theme?: string | null;
  comment?: string | null;
  user: { __typename?: "User"; id: string; profile?: { __typename?: "UserProfile"; username: string } | null };
  adoptionStatus: { __typename?: "AdoptionStatus"; comment?: string | null };
  resubmittedEntryWork?: {
    __typename: "UploadedFileResubmittedEntryWork";
    id: string;
    entryWorkId: string;
    uploadedFiles: Array<{
      __typename: "UploadedFile";
      id: string;
      fileName: string;
      filePath: string;
      contentType: string;
      previewUrl: string;
    }>;
  } | null;
  uploadedFiles: Array<{
    __typename: "UploadedFile";
    id: string;
    previewUrl: string;
    contentType: string;
    fileName: string;
  }>;
};

type SheCompetitionEntryWorkCardField_ExternalUrlEntryWork_Fragment = {
  __typename: "ExternalUrlEntryWork";
  externalUrl: string;
  id: string;
  theme?: string | null;
  comment?: string | null;
  externalUrlForEvaluation: string;
  user: { __typename?: "User"; id: string; profile?: { __typename?: "UserProfile"; username: string } | null };
  adoptionStatus: { __typename?: "AdoptionStatus"; isAdopted: boolean; comment?: string | null };
  copiedDestinations: Array<{ __typename?: "CopiedDestination"; type: FileType; fileId: string }>;
};

type SheCompetitionEntryWorkCardField_UploadedFileEntryWork_Fragment = {
  __typename: "UploadedFileEntryWork";
  id: string;
  theme?: string | null;
  comment?: string | null;
  uploadedFiles: Array<{
    __typename: "UploadedFile";
    id: string;
    previewUrl: string;
    contentType: string;
    fileName: string;
  }>;
  user: { __typename?: "User"; id: string; profile?: { __typename?: "UserProfile"; username: string } | null };
  adoptionStatus: { __typename?: "AdoptionStatus"; isAdopted: boolean; comment?: string | null };
};

export type SheCompetitionEntryWorkCardFieldFragment =
  | SheCompetitionEntryWorkCardField_ExternalUrlEntryWork_Fragment
  | SheCompetitionEntryWorkCardField_UploadedFileEntryWork_Fragment;

type EntryWorkDetailField_ExternalUrlEntryWork_Fragment = {
  __typename?: "ExternalUrlEntryWork";
  id: string;
  theme?: string | null;
  comment?: string | null;
  adoptionStatus: { __typename?: "AdoptionStatus"; comment?: string | null };
  user: { __typename?: "User"; profile?: { __typename?: "UserProfile"; username: string } | null };
};

type EntryWorkDetailField_UploadedFileEntryWork_Fragment = {
  __typename?: "UploadedFileEntryWork";
  id: string;
  theme?: string | null;
  comment?: string | null;
  adoptionStatus: { __typename?: "AdoptionStatus"; comment?: string | null };
  user: { __typename?: "User"; profile?: { __typename?: "UserProfile"; username: string } | null };
};

export type EntryWorkDetailFieldFragment =
  | EntryWorkDetailField_ExternalUrlEntryWork_Fragment
  | EntryWorkDetailField_UploadedFileEntryWork_Fragment;

export type EntryWorkDetailProjectFieldFragment = {
  __typename?: "CompetitionProject";
  id: string;
  applicantActionDueDate?: string | null;
};

type EntryWorkSummaryField_ExternalUrlEntryWork_Fragment = {
  __typename: "ExternalUrlEntryWork";
  id: string;
  theme?: string | null;
  comment?: string | null;
  externalUrl: string;
  externalUrlForEvaluation: string;
  user: { __typename?: "User"; id: string; profile?: { __typename?: "UserProfile"; username: string } | null };
  adoptionStatus: { __typename?: "AdoptionStatus"; isAdopted: boolean; comment?: string | null };
  copiedDestinations: Array<{ __typename?: "CopiedDestination"; type: FileType; fileId: string }>;
};

type EntryWorkSummaryField_UploadedFileEntryWork_Fragment = {
  __typename: "UploadedFileEntryWork";
  id: string;
  theme?: string | null;
  comment?: string | null;
  user: { __typename?: "User"; id: string; profile?: { __typename?: "UserProfile"; username: string } | null };
  adoptionStatus: { __typename?: "AdoptionStatus"; isAdopted: boolean; comment?: string | null };
  uploadedFiles: Array<{
    __typename: "UploadedFile";
    id: string;
    previewUrl: string;
    contentType: string;
    fileName: string;
  }>;
};

export type EntryWorkSummaryFieldFragment =
  | EntryWorkSummaryField_ExternalUrlEntryWork_Fragment
  | EntryWorkSummaryField_UploadedFileEntryWork_Fragment;

export type EntryWorkSummaryProjectFieldFragment = {
  __typename?: "CompetitionProject";
  competitionType: CompetitionType;
  id: string;
  applicantActionDueDate?: string | null;
};

export type ExternalUrlEntryWorkFieldsFragment = {
  __typename: "ExternalUrlEntryWork";
  externalUrl: string;
  externalUrlForEvaluation: string;
  copiedDestinations: Array<{ __typename?: "CopiedDestination"; type: FileType; fileId: string }>;
};

type ImageSliderField_ExternalUrlEntryWork_Fragment = {
  __typename: "ExternalUrlEntryWork";
  externalUrl: string;
  externalUrlForEvaluation: string;
  copiedDestinations: Array<{ __typename?: "CopiedDestination"; type: FileType; fileId: string }>;
};

type ImageSliderField_UploadedFileEntryWork_Fragment = {
  __typename: "UploadedFileEntryWork";
  uploadedFiles: Array<{
    __typename: "UploadedFile";
    id: string;
    previewUrl: string;
    contentType: string;
    fileName: string;
  }>;
};

export type ImageSliderFieldFragment =
  | ImageSliderField_ExternalUrlEntryWork_Fragment
  | ImageSliderField_UploadedFileEntryWork_Fragment;

export type ResubmittedEntryWorkImageSliderFieldsFragment = {
  __typename?: "UploadedFileResubmittedEntryWork";
  id: string;
  uploadedFiles: Array<{
    __typename: "UploadedFile";
    id: string;
    previewUrl: string;
    contentType: string;
    fileName: string;
  }>;
};

export type UploadedFileEntryWorkSliderItemFieldFragment = {
  __typename: "UploadedFile";
  id: string;
  previewUrl: string;
  contentType: string;
  fileName: string;
};

type SheCompetitionEntriesField_ExternalUrlEntryWork_Fragment = {
  __typename: "ExternalUrlEntryWork";
  id: string;
  externalUrl: string;
  theme?: string | null;
  comment?: string | null;
  externalUrlForEvaluation: string;
  user: { __typename?: "User"; id: string; profile?: { __typename?: "UserProfile"; username: string } | null };
  adoptionStatus: { __typename?: "AdoptionStatus"; isAdopted: boolean; comment?: string | null };
  copiedDestinations: Array<{ __typename?: "CopiedDestination"; type: FileType; fileId: string }>;
};

type SheCompetitionEntriesField_UploadedFileEntryWork_Fragment = {
  __typename: "UploadedFileEntryWork";
  id: string;
  theme?: string | null;
  comment?: string | null;
  user: { __typename?: "User"; id: string; profile?: { __typename?: "UserProfile"; username: string } | null };
  adoptionStatus: { __typename?: "AdoptionStatus"; isAdopted: boolean; comment?: string | null };
  uploadedFiles: Array<{
    __typename: "UploadedFile";
    id: string;
    previewUrl: string;
    contentType: string;
    fileName: string;
  }>;
  resubmittedEntryWork?: {
    __typename: "UploadedFileResubmittedEntryWork";
    id: string;
    entryWorkId: string;
    uploadedFiles: Array<{
      __typename: "UploadedFile";
      id: string;
      fileName: string;
      filePath: string;
      contentType: string;
      previewUrl: string;
    }>;
  } | null;
};

export type SheCompetitionEntriesFieldFragment =
  | SheCompetitionEntriesField_ExternalUrlEntryWork_Fragment
  | SheCompetitionEntriesField_UploadedFileEntryWork_Fragment;

export type SheCompetitionEntriesProjectFieldFragment = {
  __typename?: "CompetitionProject";
  overallFeedback: string;
  name: string;
  entryWorksVisibility: EntryWorksVisibility;
  competitionType: CompetitionType;
  id: string;
  applicantActionDueDate?: string | null;
};

export type GetEntryWorksForSheCompetitionEntriesQueryVariables = Exact<{
  projectId: Scalars["String"];
}>;

export type GetEntryWorksForSheCompetitionEntriesQuery = {
  __typename?: "Query";
  entryWorks:
    | {
        __typename: "EntryWorkListSuccess";
        result: Array<
          | {
              __typename: "ExternalUrlEntryWork";
              id: string;
              externalUrl: string;
              theme?: string | null;
              comment?: string | null;
              externalUrlForEvaluation: string;
              user: {
                __typename?: "User";
                id: string;
                profile?: { __typename?: "UserProfile"; username: string } | null;
              };
              adoptionStatus: { __typename?: "AdoptionStatus"; isAdopted: boolean; comment?: string | null };
              copiedDestinations: Array<{ __typename?: "CopiedDestination"; type: FileType; fileId: string }>;
            }
          | {
              __typename: "UploadedFileEntryWork";
              id: string;
              theme?: string | null;
              comment?: string | null;
              user: {
                __typename?: "User";
                id: string;
                profile?: { __typename?: "UserProfile"; username: string } | null;
              };
              adoptionStatus: { __typename?: "AdoptionStatus"; isAdopted: boolean; comment?: string | null };
              uploadedFiles: Array<{
                __typename: "UploadedFile";
                id: string;
                previewUrl: string;
                contentType: string;
                fileName: string;
              }>;
              resubmittedEntryWork?: {
                __typename: "UploadedFileResubmittedEntryWork";
                id: string;
                entryWorkId: string;
                uploadedFiles: Array<{
                  __typename: "UploadedFile";
                  id: string;
                  fileName: string;
                  filePath: string;
                  contentType: string;
                  previewUrl: string;
                }>;
              } | null;
            }
        >;
      }
    | { __typename: "NotAuthorized" }
    | { __typename: "NotFound" };
};

export type SheCompetitionEntriesContainerProjectFieldFragment = {
  __typename?: "CompetitionProject";
  id: string;
  overallFeedback: string;
  name: string;
  entryWorksVisibility: EntryWorksVisibility;
  competitionType: CompetitionType;
  applicantActionDueDate?: string | null;
};

export type GetProjectForEntryWorksQueryVariables = Exact<{
  projectId: Scalars["ID"];
}>;

export type GetProjectForEntryWorksQuery = {
  __typename?: "Query";
  project:
    | {
        __typename: "FindProjectSuccess";
        result:
          | {
              __typename?: "CompetitionProject";
              workCaseType: WorkCaseType;
              id: string;
              entryWorksVisibility: EntryWorksVisibility;
              name: string;
              overallFeedback: string;
              competitionType: CompetitionType;
              applicantActionDueDate?: string | null;
            }
          | { __typename?: "JobSelectionProject"; workCaseType: WorkCaseType; id: string; name: string };
      }
    | { __typename: "NotFound" };
};

export type ActionDueDateFieldFragment = { __typename?: "CompetitionProject"; applicantActionDueDate?: string | null };

export type ContractRegistrationFieldFragment = {
  __typename?: "CurrentUser";
  pasture?: { __typename?: "PastureUser"; isRegistered: boolean } | null;
};

type ResubmitBreadCrumbField_CompetitionProject_Fragment = {
  __typename?: "CompetitionProject";
  id: string;
  name: string;
};

type ResubmitBreadCrumbField_JobSelectionProject_Fragment = {
  __typename?: "JobSelectionProject";
  id: string;
  name: string;
};

export type ResubmitBreadCrumbFieldFragment =
  | ResubmitBreadCrumbField_CompetitionProject_Fragment
  | ResubmitBreadCrumbField_JobSelectionProject_Fragment;

export type ResubmitContainerGetPastureQueryVariables = Exact<{ [key: string]: never }>;

export type ResubmitContainerGetPastureQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "CurrentUser";
    pasture?: { __typename?: "PastureUser"; isRegistered: boolean } | null;
  } | null;
};

export type GetResubmitProjectQueryVariables = Exact<{
  projectId: Scalars["ID"];
}>;

export type GetResubmitProjectQuery = {
  __typename?: "Query";
  project:
    | {
        __typename?: "FindProjectSuccess";
        result:
          | { __typename?: "CompetitionProject"; applicantActionDueDate?: string | null; id: string; name: string }
          | { __typename?: "JobSelectionProject"; id: string; name: string };
      }
    | { __typename?: "NotFound" };
};

export type GetResubmittedEntryWorkQueryVariables = Exact<{
  entryWorkId: Scalars["ID"];
}>;

export type GetResubmittedEntryWorkQuery = {
  __typename?: "Query";
  getResubmittedEntryWork:
    | {
        __typename: "GetResubmittedEntryWorkSuccess";
        resubmittedEntryWork: {
          __typename: "UploadedFileResubmittedEntryWork";
          id: string;
          entryWorkId: string;
          uploadedFiles: Array<{
            __typename?: "UploadedFile";
            id: string;
            contentType: string;
            fileName: string;
            filePath: string;
            imagePreviewUrl: string;
          }>;
        };
      }
    | { __typename: "NotAuthorized"; message: string }
    | { __typename: "NotFound"; entityName: string };
};

export type ResubmitEntryWorkMutationVariables = Exact<{
  input: ResubmitEntryWorkInput;
}>;

export type ResubmitEntryWorkMutation = {
  __typename?: "Mutation";
  resubmitEntryWork:
    | { __typename: "InvalidRequest"; message: string }
    | { __typename: "NotAuthorized"; message: string }
    | { __typename: "NotFound"; id: string }
    | { __typename: "ResubmitEntryWorkSuccess"; resubmittedEntryWorkId?: string | null };
};

export type EntryFormFieldsFragment = {
  __typename: "FindProjectSuccess";
  result:
    | {
        __typename?: "CompetitionProject";
        competitionType: CompetitionType;
        id: string;
        name: string;
        description?: string | null;
        isEntryClosed: boolean;
        myEntry?:
          | {
              __typename?: "Entry";
              id: string;
              questionAnswers: Array<
                | {
                    __typename: "ConfirmationQuestionAnswer";
                    questionId: string;
                    confirmationQuestionSubmittedValue?: boolean | null;
                  }
                | {
                    __typename: "EntryResumeQuestionAnswer";
                    questionId: string;
                    entryResumeQuestionSubmittedValue?: {
                      __typename?: "UploadedFile";
                      id: string;
                      fileName: string;
                      filePath: string;
                      previewUrl: string;
                      contentType: string;
                      uploadedFileId: string;
                    } | null;
                  }
                | {
                    __typename: "EntryWorkQuestionAnswer";
                    questionId: string;
                    entryWorkQuestionSubmittedValue: Array<
                      | {
                          __typename: "ExternalUrlEntryWork";
                          externalUrl: string;
                          comment?: string | null;
                          theme?: string | null;
                          creatorName?: string | null;
                          id: string;
                          adoptionStatus: { __typename?: "AdoptionStatus"; isAdopted: boolean; isNominated: boolean };
                        }
                      | {
                          __typename: "UploadedFileEntryWork";
                          comment?: string | null;
                          theme?: string | null;
                          creatorName?: string | null;
                          id: string;
                          uploadedFiles: Array<{
                            __typename?: "UploadedFile";
                            id: string;
                            fileName: string;
                            filePath: string;
                            previewUrl: string;
                            contentType: string;
                          }>;
                          adoptionStatus: { __typename?: "AdoptionStatus"; isAdopted: boolean; isNominated: boolean };
                        }
                    >;
                  }
                | {
                    __typename: "FileQuestionAnswer";
                    questionId: string;
                    fileQuestionSubmittedValue: Array<{
                      __typename?: "UploadedFile";
                      id: string;
                      fileName: string;
                      filePath: string;
                      previewUrl: string;
                      contentType: string;
                    }>;
                  }
                | {
                    __typename: "MultiSelectQuestionAnswer";
                    questionId: string;
                    multiSelectQuestionSubmittedValue: Array<string>;
                  }
                | {
                    __typename: "SelectQuestionAnswer";
                    questionId: string;
                    selectQuestionSubmittedValue?: string | null;
                  }
                | { __typename: "TextQuestionAnswer"; questionId: string; textQuestionSubmittedValue?: string | null }
              >;
            }
          | { __typename?: "NotAuthorized" }
          | null;
        entryQuestions: Array<
          | {
              __typename: "ConfirmationQuestion";
              id: string;
              confirmationText: string;
              description?: string | null;
              label: string;
              required: boolean;
            }
          | {
              __typename: "EntryResumeQuestion";
              id: string;
              version: string;
              description?: string | null;
              label: string;
              required: boolean;
            }
          | {
              __typename: "EntryWorkQuestion";
              id: string;
              description?: string | null;
              label: string;
              required: boolean;
            }
          | {
              __typename: "EntryWorkV2Question";
              id: string;
              version: string;
              description?: string | null;
              label: string;
              required: boolean;
              uploadType?: UploadType | null;
              maxEntryWorks?: number | null;
              themes?: Array<string> | null;
              requiresCreatorName?: boolean | null;
            }
          | {
              __typename: "FileQuestion";
              id: string;
              description?: string | null;
              label: string;
              required: boolean;
              maxAttachments: number;
            }
          | {
              __typename: "MultiSelectQuestion";
              id: string;
              description?: string | null;
              label: string;
              options: Array<string>;
              required: boolean;
            }
          | {
              __typename: "SelectQuestion";
              id: string;
              description?: string | null;
              label: string;
              options: Array<string>;
              required: boolean;
            }
          | {
              __typename: "TextQuestion";
              id: string;
              description?: string | null;
              label: string;
              required: boolean;
              placeholder: string;
              multiLine: boolean;
            }
        >;
      }
    | {
        __typename?: "JobSelectionProject";
        id: string;
        name: string;
        description?: string | null;
        isEntryClosed: boolean;
        myEntry?:
          | {
              __typename?: "Entry";
              id: string;
              questionAnswers: Array<
                | {
                    __typename: "ConfirmationQuestionAnswer";
                    questionId: string;
                    confirmationQuestionSubmittedValue?: boolean | null;
                  }
                | {
                    __typename: "EntryResumeQuestionAnswer";
                    questionId: string;
                    entryResumeQuestionSubmittedValue?: {
                      __typename?: "UploadedFile";
                      id: string;
                      fileName: string;
                      filePath: string;
                      previewUrl: string;
                      contentType: string;
                      uploadedFileId: string;
                    } | null;
                  }
                | {
                    __typename: "EntryWorkQuestionAnswer";
                    questionId: string;
                    entryWorkQuestionSubmittedValue: Array<
                      | {
                          __typename: "ExternalUrlEntryWork";
                          externalUrl: string;
                          comment?: string | null;
                          theme?: string | null;
                          creatorName?: string | null;
                          id: string;
                          adoptionStatus: { __typename?: "AdoptionStatus"; isAdopted: boolean; isNominated: boolean };
                        }
                      | {
                          __typename: "UploadedFileEntryWork";
                          comment?: string | null;
                          theme?: string | null;
                          creatorName?: string | null;
                          id: string;
                          uploadedFiles: Array<{
                            __typename?: "UploadedFile";
                            id: string;
                            fileName: string;
                            filePath: string;
                            previewUrl: string;
                            contentType: string;
                          }>;
                          adoptionStatus: { __typename?: "AdoptionStatus"; isAdopted: boolean; isNominated: boolean };
                        }
                    >;
                  }
                | {
                    __typename: "FileQuestionAnswer";
                    questionId: string;
                    fileQuestionSubmittedValue: Array<{
                      __typename?: "UploadedFile";
                      id: string;
                      fileName: string;
                      filePath: string;
                      previewUrl: string;
                      contentType: string;
                    }>;
                  }
                | {
                    __typename: "MultiSelectQuestionAnswer";
                    questionId: string;
                    multiSelectQuestionSubmittedValue: Array<string>;
                  }
                | {
                    __typename: "SelectQuestionAnswer";
                    questionId: string;
                    selectQuestionSubmittedValue?: string | null;
                  }
                | { __typename: "TextQuestionAnswer"; questionId: string; textQuestionSubmittedValue?: string | null }
              >;
            }
          | { __typename?: "NotAuthorized" }
          | null;
        entryQuestions: Array<
          | {
              __typename: "ConfirmationQuestion";
              id: string;
              confirmationText: string;
              description?: string | null;
              label: string;
              required: boolean;
            }
          | {
              __typename: "EntryResumeQuestion";
              id: string;
              version: string;
              description?: string | null;
              label: string;
              required: boolean;
            }
          | {
              __typename: "EntryWorkQuestion";
              id: string;
              description?: string | null;
              label: string;
              required: boolean;
            }
          | {
              __typename: "EntryWorkV2Question";
              id: string;
              version: string;
              description?: string | null;
              label: string;
              required: boolean;
              uploadType?: UploadType | null;
              maxEntryWorks?: number | null;
              themes?: Array<string> | null;
              requiresCreatorName?: boolean | null;
            }
          | {
              __typename: "FileQuestion";
              id: string;
              description?: string | null;
              label: string;
              required: boolean;
              maxAttachments: number;
            }
          | {
              __typename: "MultiSelectQuestion";
              id: string;
              description?: string | null;
              label: string;
              options: Array<string>;
              required: boolean;
            }
          | {
              __typename: "SelectQuestion";
              id: string;
              description?: string | null;
              label: string;
              options: Array<string>;
              required: boolean;
            }
          | {
              __typename: "TextQuestion";
              id: string;
              description?: string | null;
              label: string;
              required: boolean;
              placeholder: string;
              multiLine: boolean;
            }
        >;
      };
};

export type GetProjectQueryVariables = Exact<{
  projectId: Scalars["ID"];
}>;

export type GetProjectQuery = {
  __typename?: "Query";
  project:
    | {
        __typename: "FindProjectSuccess";
        result:
          | {
              __typename?: "CompetitionProject";
              competitionType: CompetitionType;
              id: string;
              name: string;
              description?: string | null;
              isEntryClosed: boolean;
              myEntry?:
                | {
                    __typename?: "Entry";
                    id: string;
                    questionAnswers: Array<
                      | {
                          __typename: "ConfirmationQuestionAnswer";
                          questionId: string;
                          confirmationQuestionSubmittedValue?: boolean | null;
                        }
                      | {
                          __typename: "EntryResumeQuestionAnswer";
                          questionId: string;
                          entryResumeQuestionSubmittedValue?: {
                            __typename?: "UploadedFile";
                            id: string;
                            fileName: string;
                            filePath: string;
                            previewUrl: string;
                            contentType: string;
                            uploadedFileId: string;
                          } | null;
                        }
                      | {
                          __typename: "EntryWorkQuestionAnswer";
                          questionId: string;
                          entryWorkQuestionSubmittedValue: Array<
                            | {
                                __typename: "ExternalUrlEntryWork";
                                externalUrl: string;
                                comment?: string | null;
                                theme?: string | null;
                                creatorName?: string | null;
                                id: string;
                                adoptionStatus: {
                                  __typename?: "AdoptionStatus";
                                  isAdopted: boolean;
                                  isNominated: boolean;
                                };
                              }
                            | {
                                __typename: "UploadedFileEntryWork";
                                comment?: string | null;
                                theme?: string | null;
                                creatorName?: string | null;
                                id: string;
                                uploadedFiles: Array<{
                                  __typename?: "UploadedFile";
                                  id: string;
                                  fileName: string;
                                  filePath: string;
                                  previewUrl: string;
                                  contentType: string;
                                }>;
                                adoptionStatus: {
                                  __typename?: "AdoptionStatus";
                                  isAdopted: boolean;
                                  isNominated: boolean;
                                };
                              }
                          >;
                        }
                      | {
                          __typename: "FileQuestionAnswer";
                          questionId: string;
                          fileQuestionSubmittedValue: Array<{
                            __typename?: "UploadedFile";
                            id: string;
                            fileName: string;
                            filePath: string;
                            previewUrl: string;
                            contentType: string;
                          }>;
                        }
                      | {
                          __typename: "MultiSelectQuestionAnswer";
                          questionId: string;
                          multiSelectQuestionSubmittedValue: Array<string>;
                        }
                      | {
                          __typename: "SelectQuestionAnswer";
                          questionId: string;
                          selectQuestionSubmittedValue?: string | null;
                        }
                      | {
                          __typename: "TextQuestionAnswer";
                          questionId: string;
                          textQuestionSubmittedValue?: string | null;
                        }
                    >;
                  }
                | { __typename?: "NotAuthorized" }
                | null;
              entryQuestions: Array<
                | {
                    __typename: "ConfirmationQuestion";
                    id: string;
                    confirmationText: string;
                    description?: string | null;
                    label: string;
                    required: boolean;
                  }
                | {
                    __typename: "EntryResumeQuestion";
                    id: string;
                    version: string;
                    description?: string | null;
                    label: string;
                    required: boolean;
                  }
                | {
                    __typename: "EntryWorkQuestion";
                    id: string;
                    description?: string | null;
                    label: string;
                    required: boolean;
                  }
                | {
                    __typename: "EntryWorkV2Question";
                    id: string;
                    version: string;
                    description?: string | null;
                    label: string;
                    required: boolean;
                    uploadType?: UploadType | null;
                    maxEntryWorks?: number | null;
                    themes?: Array<string> | null;
                    requiresCreatorName?: boolean | null;
                  }
                | {
                    __typename: "FileQuestion";
                    id: string;
                    description?: string | null;
                    label: string;
                    required: boolean;
                    maxAttachments: number;
                  }
                | {
                    __typename: "MultiSelectQuestion";
                    id: string;
                    description?: string | null;
                    label: string;
                    options: Array<string>;
                    required: boolean;
                  }
                | {
                    __typename: "SelectQuestion";
                    id: string;
                    description?: string | null;
                    label: string;
                    options: Array<string>;
                    required: boolean;
                  }
                | {
                    __typename: "TextQuestion";
                    id: string;
                    description?: string | null;
                    label: string;
                    required: boolean;
                    placeholder: string;
                    multiLine: boolean;
                  }
              >;
            }
          | {
              __typename?: "JobSelectionProject";
              id: string;
              name: string;
              description?: string | null;
              isEntryClosed: boolean;
              myEntry?:
                | {
                    __typename?: "Entry";
                    id: string;
                    questionAnswers: Array<
                      | {
                          __typename: "ConfirmationQuestionAnswer";
                          questionId: string;
                          confirmationQuestionSubmittedValue?: boolean | null;
                        }
                      | {
                          __typename: "EntryResumeQuestionAnswer";
                          questionId: string;
                          entryResumeQuestionSubmittedValue?: {
                            __typename?: "UploadedFile";
                            id: string;
                            fileName: string;
                            filePath: string;
                            previewUrl: string;
                            contentType: string;
                            uploadedFileId: string;
                          } | null;
                        }
                      | {
                          __typename: "EntryWorkQuestionAnswer";
                          questionId: string;
                          entryWorkQuestionSubmittedValue: Array<
                            | {
                                __typename: "ExternalUrlEntryWork";
                                externalUrl: string;
                                comment?: string | null;
                                theme?: string | null;
                                creatorName?: string | null;
                                id: string;
                                adoptionStatus: {
                                  __typename?: "AdoptionStatus";
                                  isAdopted: boolean;
                                  isNominated: boolean;
                                };
                              }
                            | {
                                __typename: "UploadedFileEntryWork";
                                comment?: string | null;
                                theme?: string | null;
                                creatorName?: string | null;
                                id: string;
                                uploadedFiles: Array<{
                                  __typename?: "UploadedFile";
                                  id: string;
                                  fileName: string;
                                  filePath: string;
                                  previewUrl: string;
                                  contentType: string;
                                }>;
                                adoptionStatus: {
                                  __typename?: "AdoptionStatus";
                                  isAdopted: boolean;
                                  isNominated: boolean;
                                };
                              }
                          >;
                        }
                      | {
                          __typename: "FileQuestionAnswer";
                          questionId: string;
                          fileQuestionSubmittedValue: Array<{
                            __typename?: "UploadedFile";
                            id: string;
                            fileName: string;
                            filePath: string;
                            previewUrl: string;
                            contentType: string;
                          }>;
                        }
                      | {
                          __typename: "MultiSelectQuestionAnswer";
                          questionId: string;
                          multiSelectQuestionSubmittedValue: Array<string>;
                        }
                      | {
                          __typename: "SelectQuestionAnswer";
                          questionId: string;
                          selectQuestionSubmittedValue?: string | null;
                        }
                      | {
                          __typename: "TextQuestionAnswer";
                          questionId: string;
                          textQuestionSubmittedValue?: string | null;
                        }
                    >;
                  }
                | { __typename?: "NotAuthorized" }
                | null;
              entryQuestions: Array<
                | {
                    __typename: "ConfirmationQuestion";
                    id: string;
                    confirmationText: string;
                    description?: string | null;
                    label: string;
                    required: boolean;
                  }
                | {
                    __typename: "EntryResumeQuestion";
                    id: string;
                    version: string;
                    description?: string | null;
                    label: string;
                    required: boolean;
                  }
                | {
                    __typename: "EntryWorkQuestion";
                    id: string;
                    description?: string | null;
                    label: string;
                    required: boolean;
                  }
                | {
                    __typename: "EntryWorkV2Question";
                    id: string;
                    version: string;
                    description?: string | null;
                    label: string;
                    required: boolean;
                    uploadType?: UploadType | null;
                    maxEntryWorks?: number | null;
                    themes?: Array<string> | null;
                    requiresCreatorName?: boolean | null;
                  }
                | {
                    __typename: "FileQuestion";
                    id: string;
                    description?: string | null;
                    label: string;
                    required: boolean;
                    maxAttachments: number;
                  }
                | {
                    __typename: "MultiSelectQuestion";
                    id: string;
                    description?: string | null;
                    label: string;
                    options: Array<string>;
                    required: boolean;
                  }
                | {
                    __typename: "SelectQuestion";
                    id: string;
                    description?: string | null;
                    label: string;
                    options: Array<string>;
                    required: boolean;
                  }
                | {
                    __typename: "TextQuestion";
                    id: string;
                    description?: string | null;
                    label: string;
                    required: boolean;
                    placeholder: string;
                    multiLine: boolean;
                  }
              >;
            };
      }
    | { __typename: "NotFound"; id: string; entityName: string };
};

export type SubmitEntryMutationVariables = Exact<{
  answers: Array<AnswerInput> | AnswerInput;
  projectId: Scalars["String"];
}>;

export type SubmitEntryMutation = {
  __typename?: "Mutation";
  submitEntry:
    | { __typename: "AlreadyApplied"; message: string }
    | { __typename: "EntryClosed" }
    | { __typename: "InvalidRequest"; message: string }
    | { __typename: "NotAuthorized" }
    | { __typename: "SubmitEntrySuccess"; result: { __typename?: "Entry"; id: string } };
};

export type UpdateEntryMutationVariables = Exact<{
  answers: Array<AnswerInput> | AnswerInput;
  entryId: Scalars["String"];
}>;

export type UpdateEntryMutation = {
  __typename?: "Mutation";
  updateEntry:
    | { __typename: "EntryClosed"; message: string }
    | { __typename: "InvalidRequest"; message: string }
    | { __typename: "NotAuthorized"; message: string }
    | { __typename: "NotFound"; id: string; entityName: string }
    | { __typename: "UpdateEntrySuccess"; result: { __typename?: "Entry"; id: string } };
};

export type GetPublicProjectQueryVariables = Exact<{
  projectId: Scalars["ID"];
  signature?: InputMaybe<Scalars["String"]>;
}>;

export type GetPublicProjectQuery = {
  __typename?: "Query";
  getPublicProject:
    | {
        __typename: "GetPublicProjectSuccess";
        result?: {
          __typename?: "PublicProject";
          name: string;
          requirementDetail?: any | null;
          entryClosesAt: string;
          id: string;
          workCaseType: WorkCaseType;
          projectType: { __typename?: "ProjectType"; id: string };
          thumbnail?: { __typename?: "UploadedFile"; previewUrl: string } | null;
        } | null;
      }
    | { __typename: "InvalidRequest" }
    | { __typename: "NotFound" };
};

export type GetProjectRequirementDetailQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetProjectRequirementDetailQuery = {
  __typename?: "Query";
  getPublicProject:
    | {
        __typename: "GetPublicProjectSuccess";
        result?: { __typename?: "PublicProject"; requirementDetail?: any | null } | null;
      }
    | { __typename: "InvalidRequest" }
    | { __typename: "NotFound" };
};

export type TrackProjectPageAccessMutationVariables = Exact<{
  projectPageId: Scalars["ID"];
}>;

export type TrackProjectPageAccessMutation = {
  __typename?: "Mutation";
  trackProjectPageAccess:
    | { __typename: "InvalidRequest" }
    | { __typename: "NotAuthorized" }
    | { __typename: "TrackProjectPageAccessSuccess"; projectPageAccessHistoryId: string };
};

export type ProjectCardFieldsFragment = {
  __typename: "PublicProject";
  id: string;
  name: string;
  entryClosesAt: string;
  thumbnail?: { __typename?: "UploadedFile"; previewUrl: string } | null;
  workCase?: {
    __typename?: "WorkCase";
    company?: { __typename?: "Company"; nameFurigana?: string | null } | null;
  } | null;
  jobSettings?:
    | {
        __typename?: "ContinuousJobSettings";
        location?: { __typename?: "Location"; id: string; name: string } | null;
        jobTags?: Array<{ __typename?: "JobTag"; id: string; name: string }> | null;
        subOccupation?: { __typename?: "SubOccupation"; id: string; name: string } | null;
      }
    | {
        __typename?: "SpotJobSettings";
        location?: { __typename?: "Location"; id: string; name: string } | null;
        jobTags?: Array<{ __typename?: "JobTag"; id: string; name: string }> | null;
        jobSubCategory?: { __typename?: "JobSubCategory"; id: string; name: string } | null;
      }
    | null;
};

export const ProjectFieldFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProjectField" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Project" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectFieldFragment, unknown>;
export const BaseQuestionFieldFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BaseQuestionField" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "EntryQuestion" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "label" } },
          { kind: "Field", name: { kind: "Name", value: "required" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BaseQuestionFieldFragment, unknown>;
export const ProjectHeaderFieldFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProjectHeaderField" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Project" } },
      selectionSet: { kind: "SelectionSet", selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }] },
    },
  ],
} as unknown as DocumentNode<ProjectHeaderFieldFragment, unknown>;
export const JobWidgetFieldFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "JobWidgetField" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Job" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "image" } },
          { kind: "Field", name: { kind: "Name", value: "jobTitle" } },
          { kind: "Field", name: { kind: "Name", value: "companyName" } },
          { kind: "Field", name: { kind: "Name", value: "specificPreferences" } },
          { kind: "Field", name: { kind: "Name", value: "jobCategoryLv1" } },
          { kind: "Field", name: { kind: "Name", value: "prefecture" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobWidgetFieldFragment, unknown>;
export const JobDetailFieldFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "JobDetailField" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Job" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "FragmentSpread", name: { kind: "Name", value: "JobWidgetField" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "jobTitle" } },
          { kind: "Field", name: { kind: "Name", value: "jobDescription" } },
          { kind: "Field", name: { kind: "Name", value: "requirements" } },
          { kind: "Field", name: { kind: "Name", value: "location" } },
          { kind: "Field", name: { kind: "Name", value: "salary" } },
          { kind: "Field", name: { kind: "Name", value: "salaryDetails" } },
          { kind: "Field", name: { kind: "Name", value: "workHours" } },
          { kind: "Field", name: { kind: "Name", value: "holidays" } },
          { kind: "Field", name: { kind: "Name", value: "insurance" } },
          { kind: "Field", name: { kind: "Name", value: "benefits" } },
          { kind: "Field", name: { kind: "Name", value: "trialPeriod" } },
          { kind: "Field", name: { kind: "Name", value: "selectionProcess" } },
          { kind: "Field", name: { kind: "Name", value: "companyName" } },
          { kind: "Field", name: { kind: "Name", value: "website" } },
          { kind: "Field", name: { kind: "Name", value: "headquarters" } },
          { kind: "Field", name: { kind: "Name", value: "otherOffices" } },
          { kind: "Field", name: { kind: "Name", value: "establishmentDate" } },
          { kind: "Field", name: { kind: "Name", value: "capital" } },
          { kind: "Field", name: { kind: "Name", value: "employeeCount" } },
          { kind: "Field", name: { kind: "Name", value: "representative" } },
          { kind: "Field", name: { kind: "Name", value: "companyProfile" } },
          { kind: "Field", name: { kind: "Name", value: "smokingPolicy" } },
          { kind: "Field", name: { kind: "Name", value: "smokingPolicyDetails" } },
          { kind: "Field", name: { kind: "Name", value: "employmentType" } },
          { kind: "Field", name: { kind: "Name", value: "educationLevel" } },
          { kind: "Field", name: { kind: "Name", value: "region" } },
          { kind: "Field", name: { kind: "Name", value: "industry" } },
          { kind: "Field", name: { kind: "Name", value: "remoteWorkOk" } },
        ],
      },
    },
    ...JobWidgetFieldFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<JobDetailFieldFragment, unknown>;
export const JobCardFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "JobCardFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "JobSearchResult" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "image" } },
          { kind: "Field", name: { kind: "Name", value: "jobTitle" } },
          { kind: "Field", name: { kind: "Name", value: "companyName" } },
          { kind: "Field", name: { kind: "Name", value: "featured" } },
          { kind: "Field", name: { kind: "Name", value: "jobCategoryLv1" } },
          { kind: "Field", name: { kind: "Name", value: "prefecture" } },
          { kind: "Field", name: { kind: "Name", value: "specificPreferences" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobCardFieldsFragment, unknown>;
export const ProjectEntryWidgetFieldFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProjectEntryWidgetField" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "PublicProject" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "workCaseType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "projectType" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "entryClosesAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "thumbnail" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "previewUrl" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectEntryWidgetFieldFragment, unknown>;
export const PublicProjectTopFieldFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PublicProjectTopField" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "PublicProject" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "requirementDetail" } },
          { kind: "Field", name: { kind: "Name", value: "entryClosesAt" } },
          { kind: "FragmentSpread", name: { kind: "Name", value: "ProjectEntryWidgetField" } },
        ],
      },
    },
    ...ProjectEntryWidgetFieldFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<PublicProjectTopFieldFragment, unknown>;
export const CompetitionEntriesFieldFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CompetitionEntriesField" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Project" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "InlineFragment",
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CompetitionProject" } },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "entryWorksVisibility" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompetitionEntriesFieldFragment, unknown>;
export const EntryWorkModalFieldFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "EntryWorkModalField" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "EntryWork" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "projectComment" } },
          { kind: "Field", name: { kind: "Name", value: "sheStationUsername" } },
          {
            kind: "InlineFragment",
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "UploadedFileEntryWork" } },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "uploadedFiles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "contentType" } },
                      { kind: "Field", name: { kind: "Name", value: "previewUrl" } },
                      { kind: "Field", name: { kind: "Name", value: "fileName" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "comment" } },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ExternalUrlEntryWork" } },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "externalUrl" } }],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "adoptionStatus" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "isAdopted" } },
                { kind: "Field", name: { kind: "Name", value: "isNominated" } },
                { kind: "Field", name: { kind: "Name", value: "comment" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "comment" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EntryWorkModalFieldFragment, unknown>;
export const EntryWorkThumbnailFieldFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "EntryWorkThumbnailField" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "UploadedFile" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "contentType" } },
          { kind: "Field", name: { kind: "Name", value: "previewUrl" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EntryWorkThumbnailFieldFragment, unknown>;
export const EntryWorkCardFieldFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "EntryWorkCardField" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "EntryWork" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "FragmentSpread", name: { kind: "Name", value: "EntryWorkModalField" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "InlineFragment",
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "UploadedFileEntryWork" } },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "uploadedFiles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "EntryWorkThumbnailField" } }],
                  },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ExternalUrlEntryWork" } },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "externalUrl" } }],
            },
          },
        ],
      },
    },
    ...EntryWorkModalFieldFragmentDoc.definitions,
    ...EntryWorkThumbnailFieldFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<EntryWorkCardFieldFragment, unknown>;
export const ResubmittedEntryWorkImageSliderFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ResubmittedEntryWorkImageSliderFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ResubmittedEntryWork" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "InlineFragment",
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "UploadedFileResubmittedEntryWork" } },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "uploadedFiles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "__typename" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "previewUrl" } },
                      { kind: "Field", name: { kind: "Name", value: "contentType" } },
                      { kind: "Field", name: { kind: "Name", value: "fileName" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResubmittedEntryWorkImageSliderFieldsFragment, unknown>;
export const UploadedFileEntryWorkSliderItemFieldFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UploadedFileEntryWorkSliderItemField" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "UploadedFile" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "previewUrl" } },
          { kind: "Field", name: { kind: "Name", value: "contentType" } },
          { kind: "Field", name: { kind: "Name", value: "fileName" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UploadedFileEntryWorkSliderItemFieldFragment, unknown>;
export const CustomPagingEntryWorkFieldFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CustomPagingEntryWorkField" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "EntryWork" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          {
            kind: "InlineFragment",
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "UploadedFileEntryWork" } },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "uploadedFiles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "previewUrl" } },
                      { kind: "Field", name: { kind: "Name", value: "contentType" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomPagingEntryWorkFieldFragment, unknown>;
export const ExternalUrlEntryWorkFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ExternalUrlEntryWorkFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ExternalUrlEntryWork" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "externalUrl" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "copiedDestinations" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "fileId" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "externalUrlForEvaluation" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExternalUrlEntryWorkFieldsFragment, unknown>;
export const ImageSliderFieldFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ImageSliderField" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "EntryWork" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          {
            kind: "InlineFragment",
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "UploadedFileEntryWork" } },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "uploadedFiles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "__typename" } },
                      { kind: "FragmentSpread", name: { kind: "Name", value: "UploadedFileEntryWorkSliderItemField" } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "FragmentSpread", name: { kind: "Name", value: "CustomPagingEntryWorkField" } },
          { kind: "FragmentSpread", name: { kind: "Name", value: "ExternalUrlEntryWorkFields" } },
        ],
      },
    },
    ...UploadedFileEntryWorkSliderItemFieldFragmentDoc.definitions,
    ...CustomPagingEntryWorkFieldFragmentDoc.definitions,
    ...ExternalUrlEntryWorkFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ImageSliderFieldFragment, unknown>;
export const EntryWorkDetailFieldFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "EntryWorkDetailField" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "EntryWork" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "theme" } },
          { kind: "Field", name: { kind: "Name", value: "comment" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "adoptionStatus" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "comment" } }],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "profile" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "username" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EntryWorkDetailFieldFragment, unknown>;
export const EntryWorkSummaryFieldFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "EntryWorkSummaryField" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "EntryWork" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "FragmentSpread", name: { kind: "Name", value: "ImageSliderField" } },
          { kind: "FragmentSpread", name: { kind: "Name", value: "EntryWorkDetailField" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "adoptionStatus" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "isAdopted" } }],
            },
          },
        ],
      },
    },
    ...ImageSliderFieldFragmentDoc.definitions,
    ...EntryWorkDetailFieldFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<EntryWorkSummaryFieldFragment, unknown>;
export const SheCompetitionEntryWorkCardFieldFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SheCompetitionEntryWorkCardField" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "EntryWork" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "theme" } },
          { kind: "FragmentSpread", name: { kind: "Name", value: "EntryWorkSummaryField" } },
          {
            kind: "InlineFragment",
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "UploadedFileEntryWork" } },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "uploadedFiles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "EntryWorkThumbnailField" } }],
                  },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ExternalUrlEntryWork" } },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "externalUrl" } }],
            },
          },
        ],
      },
    },
    ...EntryWorkSummaryFieldFragmentDoc.definitions,
    ...EntryWorkThumbnailFieldFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<SheCompetitionEntryWorkCardFieldFragment, unknown>;
export const EntryWorkBeforeAfterFieldFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "EntryWorkBeforeAfterField" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "UploadedFileEntryWork" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "FragmentSpread", name: { kind: "Name", value: "ImageSliderField" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "profile" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "username" } }],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "theme" } },
          { kind: "Field", name: { kind: "Name", value: "comment" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "adoptionStatus" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "comment" } }],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "resubmittedEntryWork" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "entryWorkId" } },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UploadedFileResubmittedEntryWork" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "uploadedFiles" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "fileName" } },
                            { kind: "Field", name: { kind: "Name", value: "filePath" } },
                            { kind: "Field", name: { kind: "Name", value: "contentType" } },
                            { kind: "Field", name: { kind: "Name", value: "previewUrl" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ImageSliderFieldFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<EntryWorkBeforeAfterFieldFragment, unknown>;
export const SheCompetitionEntriesFieldFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SheCompetitionEntriesField" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "EntryWork" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "FragmentSpread", name: { kind: "Name", value: "EntryWorkSummaryField" } },
          { kind: "FragmentSpread", name: { kind: "Name", value: "SheCompetitionEntryWorkCardField" } },
          { kind: "FragmentSpread", name: { kind: "Name", value: "EntryWorkBeforeAfterField" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "adoptionStatus" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "isAdopted" } }],
            },
          },
        ],
      },
    },
    ...EntryWorkSummaryFieldFragmentDoc.definitions,
    ...SheCompetitionEntryWorkCardFieldFragmentDoc.definitions,
    ...EntryWorkBeforeAfterFieldFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<SheCompetitionEntriesFieldFragment, unknown>;
export const EntryWorkDetailProjectFieldFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "EntryWorkDetailProjectField" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CompetitionProject" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "applicantActionDueDate" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EntryWorkDetailProjectFieldFragment, unknown>;
export const EntryWorkSummaryProjectFieldFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "EntryWorkSummaryProjectField" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CompetitionProject" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "FragmentSpread", name: { kind: "Name", value: "EntryWorkDetailProjectField" } },
          { kind: "Field", name: { kind: "Name", value: "competitionType" } },
        ],
      },
    },
    ...EntryWorkDetailProjectFieldFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<EntryWorkSummaryProjectFieldFragment, unknown>;
export const SheCompetitionEntriesProjectFieldFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SheCompetitionEntriesProjectField" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CompetitionProject" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "overallFeedback" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "entryWorksVisibility" } },
          { kind: "FragmentSpread", name: { kind: "Name", value: "EntryWorkSummaryProjectField" } },
        ],
      },
    },
    ...EntryWorkSummaryProjectFieldFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<SheCompetitionEntriesProjectFieldFragment, unknown>;
export const SheCompetitionEntriesContainerProjectFieldFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SheCompetitionEntriesContainerProjectField" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CompetitionProject" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "FragmentSpread", name: { kind: "Name", value: "SheCompetitionEntriesProjectField" } },
        ],
      },
    },
    ...SheCompetitionEntriesProjectFieldFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<SheCompetitionEntriesContainerProjectFieldFragment, unknown>;
export const ActionDueDateFieldFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ActionDueDateField" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CompetitionProject" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [{ kind: "Field", name: { kind: "Name", value: "applicantActionDueDate" } }],
      },
    },
  ],
} as unknown as DocumentNode<ActionDueDateFieldFragment, unknown>;
export const ContractRegistrationFieldFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ContractRegistrationField" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CurrentUser" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "pasture" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "isRegistered" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContractRegistrationFieldFragment, unknown>;
export const ResubmitBreadCrumbFieldFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ResubmitBreadCrumbField" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Project" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResubmitBreadCrumbFieldFragment, unknown>;
export const TextQuestionAnswerFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TextQuestionAnswerFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "TextQuestionAnswer" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "questionId" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "textQuestionSubmittedValue" },
            name: { kind: "Name", value: "submittedValue" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TextQuestionAnswerFieldsFragment, unknown>;
export const ConfirmationQuestionAnswerFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ConfirmationQuestionAnswerFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ConfirmationQuestionAnswer" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "questionId" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "confirmationQuestionSubmittedValue" },
            name: { kind: "Name", value: "submittedValue" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConfirmationQuestionAnswerFieldsFragment, unknown>;
export const SelectQuestionAnswerFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SelectQuestionAnswerFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "SelectQuestionAnswer" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "questionId" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "selectQuestionSubmittedValue" },
            name: { kind: "Name", value: "submittedValue" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SelectQuestionAnswerFieldsFragment, unknown>;
export const MultiSelectQuestionAnswerFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MultiSelectQuestionAnswerFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MultiSelectQuestionAnswer" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "questionId" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "multiSelectQuestionSubmittedValue" },
            name: { kind: "Name", value: "submittedValue" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MultiSelectQuestionAnswerFieldsFragment, unknown>;
export const FileQuestionAnswerFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FileQuestionAnswerFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "FileQuestionAnswer" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "questionId" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "fileQuestionSubmittedValue" },
            name: { kind: "Name", value: "submittedValue" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "fileName" } },
                { kind: "Field", name: { kind: "Name", value: "filePath" } },
                { kind: "Field", name: { kind: "Name", value: "previewUrl" } },
                { kind: "Field", name: { kind: "Name", value: "contentType" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FileQuestionAnswerFieldsFragment, unknown>;
export const EntryWorkQuestionAnswerFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "EntryWorkQuestionAnswerFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "EntryWorkQuestionAnswer" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "questionId" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "entryWorkQuestionSubmittedValue" },
            name: { kind: "Name", value: "submittedValue" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "adoptionStatus" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "isAdopted" } },
                      { kind: "Field", name: { kind: "Name", value: "isNominated" } },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "UploadedFileEntryWork" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "__typename" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "uploadedFiles" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "fileName" } },
                            { kind: "Field", name: { kind: "Name", value: "filePath" } },
                            { kind: "Field", name: { kind: "Name", value: "previewUrl" } },
                            { kind: "Field", name: { kind: "Name", value: "contentType" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "comment" } },
                      { kind: "Field", name: { kind: "Name", value: "theme" } },
                      { kind: "Field", name: { kind: "Name", value: "creatorName" } },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ExternalUrlEntryWork" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "__typename" } },
                      { kind: "Field", name: { kind: "Name", value: "externalUrl" } },
                      { kind: "Field", name: { kind: "Name", value: "comment" } },
                      { kind: "Field", name: { kind: "Name", value: "theme" } },
                      { kind: "Field", name: { kind: "Name", value: "creatorName" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EntryWorkQuestionAnswerFieldsFragment, unknown>;
export const EntryResumeQuestionAnswerFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "EntryResumeQuestionAnswerFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "EntryResumeQuestionAnswer" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "questionId" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "entryResumeQuestionSubmittedValue" },
            name: { kind: "Name", value: "submittedValue" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "uploadedFileId" },
                  name: { kind: "Name", value: "id" },
                },
                { kind: "Field", name: { kind: "Name", value: "fileName" } },
                { kind: "Field", name: { kind: "Name", value: "filePath" } },
                { kind: "Field", name: { kind: "Name", value: "previewUrl" } },
                { kind: "Field", name: { kind: "Name", value: "contentType" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EntryResumeQuestionAnswerFieldsFragment, unknown>;
export const TextQuestionFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TextQuestionFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "TextQuestion" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "label" } },
          { kind: "Field", name: { kind: "Name", value: "required" } },
          { kind: "Field", name: { kind: "Name", value: "placeholder" } },
          { kind: "Field", name: { kind: "Name", value: "multiLine" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TextQuestionFieldsFragment, unknown>;
export const ConfirmationQuestionFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ConfirmationQuestionFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ConfirmationQuestion" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "confirmationText" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "label" } },
          { kind: "Field", name: { kind: "Name", value: "required" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConfirmationQuestionFieldsFragment, unknown>;
export const SelectQuestionFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SelectQuestionFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "SelectQuestion" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "label" } },
          { kind: "Field", name: { kind: "Name", value: "options" } },
          { kind: "Field", name: { kind: "Name", value: "required" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SelectQuestionFieldsFragment, unknown>;
export const MultiSelectQuestionFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MultiSelectQuestionFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MultiSelectQuestion" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "label" } },
          { kind: "Field", name: { kind: "Name", value: "options" } },
          { kind: "Field", name: { kind: "Name", value: "required" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MultiSelectQuestionFieldsFragment, unknown>;
export const FileQuestionFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FileQuestionFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "FileQuestion" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "label" } },
          { kind: "Field", name: { kind: "Name", value: "required" } },
          { kind: "Field", name: { kind: "Name", value: "maxAttachments" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FileQuestionFieldsFragment, unknown>;
export const EntryWorkQuestionFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "EntryWorkQuestionFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "EntryWorkQuestion" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "label" } },
          { kind: "Field", name: { kind: "Name", value: "required" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EntryWorkQuestionFieldsFragment, unknown>;
export const EntryWorkV2QuestionFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "EntryWorkV2QuestionFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "EntryWorkV2Question" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "version" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "label" } },
          { kind: "Field", name: { kind: "Name", value: "required" } },
          { kind: "Field", name: { kind: "Name", value: "uploadType" } },
          { kind: "Field", name: { kind: "Name", value: "maxEntryWorks" } },
          { kind: "Field", name: { kind: "Name", value: "themes" } },
          { kind: "Field", name: { kind: "Name", value: "requiresCreatorName" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EntryWorkV2QuestionFieldsFragment, unknown>;
export const EntryResumeQuestionFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "EntryResumeQuestionFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "EntryResumeQuestion" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "version" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "label" } },
          { kind: "Field", name: { kind: "Name", value: "required" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EntryResumeQuestionFieldsFragment, unknown>;
export const EntryFormFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "EntryFormFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "FindProjectSuccess" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "result" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "isEntryClosed" } },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CompetitionProject" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "competitionType" } }],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "myEntry" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Entry" } },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "questionAnswers" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "EntryQuestionAnswer" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [{ kind: "Field", name: { kind: "Name", value: "questionId" } }],
                                    },
                                  },
                                  { kind: "FragmentSpread", name: { kind: "Name", value: "TextQuestionAnswerFields" } },
                                  {
                                    kind: "FragmentSpread",
                                    name: { kind: "Name", value: "ConfirmationQuestionAnswerFields" },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: { kind: "Name", value: "SelectQuestionAnswerFields" },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: { kind: "Name", value: "MultiSelectQuestionAnswerFields" },
                                  },
                                  { kind: "FragmentSpread", name: { kind: "Name", value: "FileQuestionAnswerFields" } },
                                  {
                                    kind: "FragmentSpread",
                                    name: { kind: "Name", value: "EntryWorkQuestionAnswerFields" },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: { kind: "Name", value: "EntryResumeQuestionAnswerFields" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "entryQuestions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "__typename" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "FragmentSpread", name: { kind: "Name", value: "TextQuestionFields" } },
                      { kind: "FragmentSpread", name: { kind: "Name", value: "ConfirmationQuestionFields" } },
                      { kind: "FragmentSpread", name: { kind: "Name", value: "SelectQuestionFields" } },
                      { kind: "FragmentSpread", name: { kind: "Name", value: "MultiSelectQuestionFields" } },
                      { kind: "FragmentSpread", name: { kind: "Name", value: "FileQuestionFields" } },
                      { kind: "FragmentSpread", name: { kind: "Name", value: "EntryWorkQuestionFields" } },
                      { kind: "FragmentSpread", name: { kind: "Name", value: "EntryWorkV2QuestionFields" } },
                      { kind: "FragmentSpread", name: { kind: "Name", value: "EntryResumeQuestionFields" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...TextQuestionAnswerFieldsFragmentDoc.definitions,
    ...ConfirmationQuestionAnswerFieldsFragmentDoc.definitions,
    ...SelectQuestionAnswerFieldsFragmentDoc.definitions,
    ...MultiSelectQuestionAnswerFieldsFragmentDoc.definitions,
    ...FileQuestionAnswerFieldsFragmentDoc.definitions,
    ...EntryWorkQuestionAnswerFieldsFragmentDoc.definitions,
    ...EntryResumeQuestionAnswerFieldsFragmentDoc.definitions,
    ...TextQuestionFieldsFragmentDoc.definitions,
    ...ConfirmationQuestionFieldsFragmentDoc.definitions,
    ...SelectQuestionFieldsFragmentDoc.definitions,
    ...MultiSelectQuestionFieldsFragmentDoc.definitions,
    ...FileQuestionFieldsFragmentDoc.definitions,
    ...EntryWorkQuestionFieldsFragmentDoc.definitions,
    ...EntryWorkV2QuestionFieldsFragmentDoc.definitions,
    ...EntryResumeQuestionFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<EntryFormFieldsFragment, unknown>;
export const ProjectCardFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProjectCardFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "PublicProject" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "thumbnail" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "previewUrl" } }],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "entryClosesAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "workCase" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "company" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "nameFurigana" } }],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "jobSettings" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "SpotJobSettings" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "location" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "jobTags" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "jobSubCategory" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ContinuousJobSettings" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "location" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "jobTags" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subOccupation" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectCardFieldsFragment, unknown>;
export const FormEntryResumeRequestUploadDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "FormEntryResumeRequestUpload" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "RequestUploadInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "requestUpload" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "RequestUploadSuccess" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "result" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "uploadUrl" } },
                            { kind: "Field", name: { kind: "Name", value: "filePath" } },
                            { kind: "Field", name: { kind: "Name", value: "contentType" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "InvalidRequest" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "message" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FormEntryResumeRequestUploadMutation, FormEntryResumeRequestUploadMutationVariables>;
export const EntryWorkV2QuestionFormRequestUploadDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "EntryWorkV2QuestionFormRequestUpload" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "RequestUploadInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "requestUpload" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "RequestUploadSuccess" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "result" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "uploadUrl" } },
                            { kind: "Field", name: { kind: "Name", value: "filePath" } },
                            { kind: "Field", name: { kind: "Name", value: "contentType" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "InvalidRequest" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "message" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EntryWorkV2QuestionFormRequestUploadMutation,
  EntryWorkV2QuestionFormRequestUploadMutationVariables
>;
export const EntryWorkQuestionFormRequestUploadDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "EntryWorkQuestionFormRequestUpload" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "RequestUploadInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "requestUpload" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "RequestUploadSuccess" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "result" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "uploadUrl" } },
                            { kind: "Field", name: { kind: "Name", value: "filePath" } },
                            { kind: "Field", name: { kind: "Name", value: "contentType" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "InvalidRequest" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "message" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EntryWorkQuestionFormRequestUploadMutation,
  EntryWorkQuestionFormRequestUploadMutationVariables
>;
export const FileQuestionFormRequestUploadDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "FileQuestionFormRequestUpload" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "RequestUploadInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "requestUpload" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "RequestUploadSuccess" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "result" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "uploadUrl" } },
                            { kind: "Field", name: { kind: "Name", value: "filePath" } },
                            { kind: "Field", name: { kind: "Name", value: "contentType" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "InvalidRequest" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "message" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FileQuestionFormRequestUploadMutation, FileQuestionFormRequestUploadMutationVariables>;
export const FileUploadRequestUploadDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "FileUploadRequestUpload" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "RequestUploadInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "requestUpload" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "RequestUploadSuccess" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "result" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "uploadUrl" } },
                            { kind: "Field", name: { kind: "Name", value: "filePath" } },
                            { kind: "Field", name: { kind: "Name", value: "contentType" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "InvalidRequest" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "message" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FileUploadRequestUploadMutation, FileUploadRequestUploadMutationVariables>;
export const SearchPublicProjectsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SearchPublicProjects" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "itemsPerPage" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Int" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "SearchProjectsInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "searchProjects" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "page" },
                value: { kind: "Variable", name: { kind: "Name", value: "page" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "itemsPerPage" },
                value: { kind: "Variable", name: { kind: "Name", value: "itemsPerPage" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "PublicProjectConnection" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "ProjectCardFields" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pageInfo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "page" } },
                            { kind: "Field", name: { kind: "Name", value: "hasNextPage" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ProjectCardFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<SearchPublicProjectsQuery, SearchPublicProjectsQueryVariables>;
export const CurrentUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "currentUser" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "currentUser" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "profilePhotoUrl" } },
                { kind: "Field", name: { kind: "Name", value: "isActiveUser" } },
                { kind: "Field", name: { kind: "Name", value: "isGeneralStaffUser" } },
                { kind: "Field", name: { kind: "Name", value: "isWorksAdminUser" } },
                { kind: "Field", name: { kind: "Name", value: "isWorksStaffUser" } },
                { kind: "Field", name: { kind: "Name", value: "isWorksClientUser" } },
                { kind: "Field", name: { kind: "Name", value: "isWorksShemateUser" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "enabledFeatures" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "Field", name: { kind: "Name", value: "enabled" } },
                { kind: "Field", name: { kind: "Name", value: "key" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CurrentUserQuery, CurrentUserQueryVariables>;
export const GetJobDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetJob" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getJob" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "GetJobSuccess" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "job" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "JobDetailField" } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...JobDetailFieldFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetJobQuery, GetJobQueryVariables>;
export const SearchJobsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SearchJobs" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "itemsPerPage" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Int" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "SearchJobsInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "searchJobs" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "page" },
                value: { kind: "Variable", name: { kind: "Name", value: "page" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "itemsPerPage" },
                value: { kind: "Variable", name: { kind: "Name", value: "itemsPerPage" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "JobConnection" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "JobCardFields" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pageInfo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "hasNextPage" } },
                            { kind: "Field", name: { kind: "Name", value: "page" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...JobCardFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<SearchJobsQuery, SearchJobsQueryVariables>;
export const GetEntryWorksDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetEntryWorks" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "projectId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "entryWorks" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "projectId" },
                value: { kind: "Variable", name: { kind: "Name", value: "projectId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "EntryWorkListSuccess" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "result" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "EntryWorkCardField" } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...EntryWorkCardFieldFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetEntryWorksQuery, GetEntryWorksQueryVariables>;
export const GetEntryWorksForSheCompetitionEntriesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetEntryWorksForSheCompetitionEntries" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "projectId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "entryWorks" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "projectId" },
                value: { kind: "Variable", name: { kind: "Name", value: "projectId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "EntryWorkListSuccess" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "result" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "FragmentSpread", name: { kind: "Name", value: "SheCompetitionEntriesField" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...SheCompetitionEntriesFieldFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetEntryWorksForSheCompetitionEntriesQuery,
  GetEntryWorksForSheCompetitionEntriesQueryVariables
>;
export const GetProjectForEntryWorksDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetProjectForEntryWorks" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "projectId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "project" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "projectId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "FindProjectSuccess" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "result" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "FragmentSpread", name: { kind: "Name", value: "CompetitionEntriesField" } },
                            { kind: "Field", name: { kind: "Name", value: "workCaseType" } },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CompetitionProject" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: { kind: "Name", value: "SheCompetitionEntriesContainerProjectField" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CompetitionEntriesFieldFragmentDoc.definitions,
    ...SheCompetitionEntriesContainerProjectFieldFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetProjectForEntryWorksQuery, GetProjectForEntryWorksQueryVariables>;
export const ResubmitContainerGetPastureDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ResubmitContainerGetPasture" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "currentUser" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CurrentUser" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "FragmentSpread", name: { kind: "Name", value: "ContractRegistrationField" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ContractRegistrationFieldFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ResubmitContainerGetPastureQuery, ResubmitContainerGetPastureQueryVariables>;
export const GetResubmitProjectDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetResubmitProject" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "projectId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "project" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "projectId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "FindProjectSuccess" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "result" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "FragmentSpread", name: { kind: "Name", value: "ResubmitBreadCrumbField" } },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CompetitionProject" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "FragmentSpread", name: { kind: "Name", value: "ActionDueDateField" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ResubmitBreadCrumbFieldFragmentDoc.definitions,
    ...ActionDueDateFieldFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetResubmitProjectQuery, GetResubmitProjectQueryVariables>;
export const GetResubmittedEntryWorkDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetResubmittedEntryWork" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "entryWorkId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getResubmittedEntryWork" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "entryWorkId" },
                value: { kind: "Variable", name: { kind: "Name", value: "entryWorkId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "GetResubmittedEntryWorkSuccess" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "resubmittedEntryWork" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "UploadedFileResubmittedEntryWork" },
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "entryWorkId" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uploadedFiles" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "contentType" } },
                                        { kind: "Field", name: { kind: "Name", value: "fileName" } },
                                        { kind: "Field", name: { kind: "Name", value: "filePath" } },
                                        {
                                          kind: "Field",
                                          alias: { kind: "Name", value: "imagePreviewUrl" },
                                          name: { kind: "Name", value: "previewUrl" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "NotAuthorized" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "message" } }],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "NotFound" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "entityName" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetResubmittedEntryWorkQuery, GetResubmittedEntryWorkQueryVariables>;
export const ResubmitEntryWorkDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ResubmitEntryWork" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ResubmitEntryWorkInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "resubmitEntryWork" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ResubmitEntryWorkSuccess" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "resubmittedEntryWorkId" } }],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "InvalidRequest" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "message" } }],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "NotAuthorized" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "message" } }],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "NotFound" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResubmitEntryWorkMutation, ResubmitEntryWorkMutationVariables>;
export const GetProjectDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetProject" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "projectId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "project" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "projectId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "FragmentSpread", name: { kind: "Name", value: "EntryFormFields" } },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "NotFound" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "entityName" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...EntryFormFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetProjectQuery, GetProjectQueryVariables>;
export const SubmitEntryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SubmitEntry" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "answers" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "AnswerInput" } } },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "projectId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "submitEntry" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "answers" },
                value: { kind: "Variable", name: { kind: "Name", value: "answers" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "projectId" },
                value: { kind: "Variable", name: { kind: "Name", value: "projectId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "SubmitEntrySuccess" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "result" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "AlreadyApplied" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "message" } }],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "InvalidRequest" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "message" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubmitEntryMutation, SubmitEntryMutationVariables>;
export const UpdateEntryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateEntry" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "answers" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "AnswerInput" } } },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "entryId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateEntry" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "answers" },
                value: { kind: "Variable", name: { kind: "Name", value: "answers" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "entryId" },
                value: { kind: "Variable", name: { kind: "Name", value: "entryId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "EntryClosed" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "message" } }],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "InvalidRequest" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "message" } }],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "NotAuthorized" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "message" } }],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "NotFound" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "entityName" } },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "UpdateEntrySuccess" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "result" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateEntryMutation, UpdateEntryMutationVariables>;
export const GetPublicProjectDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetPublicProject" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "projectId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "signature" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getPublicProject" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "projectId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "signature" },
                value: { kind: "Variable", name: { kind: "Name", value: "signature" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "GetPublicProjectSuccess" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "result" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "FragmentSpread", name: { kind: "Name", value: "PublicProjectTopField" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PublicProjectTopFieldFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetPublicProjectQuery, GetPublicProjectQueryVariables>;
export const GetProjectRequirementDetailDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getProjectRequirementDetail" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getPublicProject" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "GetPublicProjectSuccess" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "result" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "requirementDetail" } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProjectRequirementDetailQuery, GetProjectRequirementDetailQueryVariables>;
export const TrackProjectPageAccessDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "TrackProjectPageAccess" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "projectPageId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "trackProjectPageAccess" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "projectPageId" },
                      value: { kind: "Variable", name: { kind: "Name", value: "projectPageId" } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "TrackProjectPageAccessSuccess" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "projectPageAccessHistoryId" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TrackProjectPageAccessMutation, TrackProjectPageAccessMutationVariables>;

export function useFormEntryResumeRequestUploadMutation() {
  return Urql.useMutation<FormEntryResumeRequestUploadMutation, FormEntryResumeRequestUploadMutationVariables>(
    FormEntryResumeRequestUploadDocument,
  );
}

export function useEntryWorkV2QuestionFormRequestUploadMutation() {
  return Urql.useMutation<
    EntryWorkV2QuestionFormRequestUploadMutation,
    EntryWorkV2QuestionFormRequestUploadMutationVariables
  >(EntryWorkV2QuestionFormRequestUploadDocument);
}

export function useEntryWorkQuestionFormRequestUploadMutation() {
  return Urql.useMutation<
    EntryWorkQuestionFormRequestUploadMutation,
    EntryWorkQuestionFormRequestUploadMutationVariables
  >(EntryWorkQuestionFormRequestUploadDocument);
}

export function useFileQuestionFormRequestUploadMutation() {
  return Urql.useMutation<FileQuestionFormRequestUploadMutation, FileQuestionFormRequestUploadMutationVariables>(
    FileQuestionFormRequestUploadDocument,
  );
}

export function useFileUploadRequestUploadMutation() {
  return Urql.useMutation<FileUploadRequestUploadMutation, FileUploadRequestUploadMutationVariables>(
    FileUploadRequestUploadDocument,
  );
}

export function useSearchPublicProjectsQuery(
  options: Omit<Urql.UseQueryArgs<SearchPublicProjectsQueryVariables>, "query">,
) {
  return Urql.useQuery<SearchPublicProjectsQuery, SearchPublicProjectsQueryVariables>({
    query: SearchPublicProjectsDocument,
    ...options,
  });
}

export function useCurrentUserQuery(options?: Omit<Urql.UseQueryArgs<CurrentUserQueryVariables>, "query">) {
  return Urql.useQuery<CurrentUserQuery, CurrentUserQueryVariables>({ query: CurrentUserDocument, ...options });
}

export function useGetJobQuery(options: Omit<Urql.UseQueryArgs<GetJobQueryVariables>, "query">) {
  return Urql.useQuery<GetJobQuery, GetJobQueryVariables>({ query: GetJobDocument, ...options });
}

export function useSearchJobsQuery(options: Omit<Urql.UseQueryArgs<SearchJobsQueryVariables>, "query">) {
  return Urql.useQuery<SearchJobsQuery, SearchJobsQueryVariables>({ query: SearchJobsDocument, ...options });
}

export function useGetEntryWorksQuery(options: Omit<Urql.UseQueryArgs<GetEntryWorksQueryVariables>, "query">) {
  return Urql.useQuery<GetEntryWorksQuery, GetEntryWorksQueryVariables>({ query: GetEntryWorksDocument, ...options });
}

export function useGetEntryWorksForSheCompetitionEntriesQuery(
  options: Omit<Urql.UseQueryArgs<GetEntryWorksForSheCompetitionEntriesQueryVariables>, "query">,
) {
  return Urql.useQuery<GetEntryWorksForSheCompetitionEntriesQuery, GetEntryWorksForSheCompetitionEntriesQueryVariables>(
    { query: GetEntryWorksForSheCompetitionEntriesDocument, ...options },
  );
}

export function useGetProjectForEntryWorksQuery(
  options: Omit<Urql.UseQueryArgs<GetProjectForEntryWorksQueryVariables>, "query">,
) {
  return Urql.useQuery<GetProjectForEntryWorksQuery, GetProjectForEntryWorksQueryVariables>({
    query: GetProjectForEntryWorksDocument,
    ...options,
  });
}

export function useResubmitContainerGetPastureQuery(
  options?: Omit<Urql.UseQueryArgs<ResubmitContainerGetPastureQueryVariables>, "query">,
) {
  return Urql.useQuery<ResubmitContainerGetPastureQuery, ResubmitContainerGetPastureQueryVariables>({
    query: ResubmitContainerGetPastureDocument,
    ...options,
  });
}

export function useGetResubmitProjectQuery(
  options: Omit<Urql.UseQueryArgs<GetResubmitProjectQueryVariables>, "query">,
) {
  return Urql.useQuery<GetResubmitProjectQuery, GetResubmitProjectQueryVariables>({
    query: GetResubmitProjectDocument,
    ...options,
  });
}

export function useGetResubmittedEntryWorkQuery(
  options: Omit<Urql.UseQueryArgs<GetResubmittedEntryWorkQueryVariables>, "query">,
) {
  return Urql.useQuery<GetResubmittedEntryWorkQuery, GetResubmittedEntryWorkQueryVariables>({
    query: GetResubmittedEntryWorkDocument,
    ...options,
  });
}

export function useResubmitEntryWorkMutation() {
  return Urql.useMutation<ResubmitEntryWorkMutation, ResubmitEntryWorkMutationVariables>(ResubmitEntryWorkDocument);
}

export function useGetProjectQuery(options: Omit<Urql.UseQueryArgs<GetProjectQueryVariables>, "query">) {
  return Urql.useQuery<GetProjectQuery, GetProjectQueryVariables>({ query: GetProjectDocument, ...options });
}

export function useSubmitEntryMutation() {
  return Urql.useMutation<SubmitEntryMutation, SubmitEntryMutationVariables>(SubmitEntryDocument);
}

export function useUpdateEntryMutation() {
  return Urql.useMutation<UpdateEntryMutation, UpdateEntryMutationVariables>(UpdateEntryDocument);
}

export function useGetPublicProjectQuery(options: Omit<Urql.UseQueryArgs<GetPublicProjectQueryVariables>, "query">) {
  return Urql.useQuery<GetPublicProjectQuery, GetPublicProjectQueryVariables>({
    query: GetPublicProjectDocument,
    ...options,
  });
}

export function useGetProjectRequirementDetailQuery(
  options: Omit<Urql.UseQueryArgs<GetProjectRequirementDetailQueryVariables>, "query">,
) {
  return Urql.useQuery<GetProjectRequirementDetailQuery, GetProjectRequirementDetailQueryVariables>({
    query: GetProjectRequirementDetailDocument,
    ...options,
  });
}

export function useTrackProjectPageAccessMutation() {
  return Urql.useMutation<TrackProjectPageAccessMutation, TrackProjectPageAccessMutationVariables>(
    TrackProjectPageAccessDocument,
  );
}
